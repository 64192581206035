import {createAction} from "redux-actions";
import {
    commonGetCurrentQuestion,
    commonGetNextQuestion,
    commonGetPrevQuestion,
    commonGetQuestion, commonGetQuoteUUID, commonReset
} from "./_common.step.actions";

const actions = {
    step_get_question: createAction("ADMIN_GET_QUESTION"),
    step_succeeded: createAction("ADMIN_STEP_SUCCEEDED"),
    step_failed: createAction("ADMIN_STEP_FAILED"),
    step_reset: createAction("ADMIN_STEP_RESET"),

    clear_address_lookup_errors: createAction("ADMIN_STEP_CLEAR_ADDRESS_LOOKUP_ERROR"),
    clear_vehicle_lookup_errors: createAction("ADMIN_STEP_CLEAR_VEHICLE_LOOKUP_ERROR"),
};

export default actions;

export const getNextQuestion = commonGetNextQuestion(actions.step_get_question.toString(), actions.step_succeeded.toString(), actions.step_failed.toString())('admin/');
export const getPrevQuestion = commonGetPrevQuestion(actions.step_get_question.toString(), actions.step_succeeded.toString(), actions.step_failed.toString())('admin/');
export const getCurrentQuestion = commonGetCurrentQuestion(actions.step_get_question.toString(), actions.step_succeeded.toString(), actions.step_failed.toString())('admin/');
export const getQuestion = commonGetQuestion(actions.step_get_question.toString(), actions.step_succeeded.toString(), actions.step_failed.toString())('admin/');
export const reset = commonReset(actions.step_reset.toString());


export const getQuoteUUID = commonGetQuoteUUID(actions.step_get_question.toString(), actions.step_succeeded.toString(), actions.step_failed.toString())();

export const clearAddressLookupError = () => actions.clear_address_lookup_errors();
export const clearVehicleLookupError = () => actions.clear_vehicle_lookup_errors();
