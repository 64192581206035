import React from 'react';
import {Button, Col, Container, Row} from "reactstrap";
import config from "../config";
import '../styles/page.thank.you.scss';
import {useHistory} from "react-router-dom";

const PageThankYou = () => {
    const history = useHistory();

    return (
        <Container className={'page-thank-you'}>
            <div id="complete" hidden className='visiblity: hidden;'></div>
            <Row>
                <Col>
                    <h1 className='text-capitalize'>thank you</h1>
                    <p>
                        Thank you for providing your information to obtain an insurance quote. Due to the nature of the risk the premium has to be signed off from a
                        underwriter. This normally takes around 10 minutes but please provide upto an hour.
                    </p>
                    <p>
                        One of our sales advisers will be in touch with you shortly.
                    </p>
                    <p>
                        Our phone number to call us <strong><a className={'telephone'} href={`tel:${config.phoneNumbers.undefined}`}>{config.phoneNumbers.undefined}</a></strong>
                    </p>
                    <hr/>
                    <h2>Our Opening Hours <span className='text-muted'>(Excluding Bank Holidays)</span></h2>
                    <p>
                        {config.timeTable.week.text}
                    </p>
                    <p>
                        {config.timeTable.saturday.text}
                    </p>
                    <hr/>
                    <div className={'text-center p-1'}>
                        <Button color={'danger'} onClick={() => history.push('/')} size={'lg'}>Home</Button>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default PageThankYou;
