import {createAction} from 'redux-actions';
import {apiPost, apiGet} from "./api";

const actions = {
    buy_get_quote: createAction('BUY_GET_QUOTE'),

    buy_risk_succeeded: createAction('BUY_RISK_SUCCEEDED'),
    buy_risk_failed: createAction('BUY_RISK_FAILED')
};

export default actions;

const send = (uuid, response) => {
    return {
        type: actions.buy_get_quote.toString(),
        uuid,
        payload: response
    }
};

const riskSucceeded = (uuid, res) => {
    return {
        type: actions.buy_risk_succeeded.toString(),
        uuid,
        payload: res
    }
}

const riskFailed = (uuid, res) => {
    return {
        type: actions.buy_risk_failed.toString(),
        uuid,
        payload: res
    }
}


export const getQuote = (path, data) => {
    const {uuid} = data;
    return apiGet(`buy/${path}`, data,
        res => send(uuid, res),
        res => riskSucceeded(uuid, {...res, path}),
        res => riskFailed(uuid, {...res, path})
    );
}

export const submit = (path, data) => {
    const {uuid} = data;
    return apiPost(`buy/${path}`, data,
        res => send(uuid, res),
        res => riskSucceeded(uuid, {...res, path}),
        res => riskFailed(uuid, {...res, path})
    );
}
