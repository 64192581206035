import {createTransform} from "redux-persist";

const persistence = (state, key, config) => {
    if (config.autoExpire) {//update time automatically
        return {...state, [config.expirationKey]: new Date().getTime()};
    }
    return state;
};

const rehydrate = (state, key, config) => {
    //check expiration
    if (config.expireSeconds && state[config.expirationKey]) {
        const time = new Date(state[config.expirationKey]).getTime();//ms
        const end = new Date().getTime();
        const seconds = (end - time) / 1000;
        if (seconds > config.expireSeconds) {
            //clear state
            return config.defaultState
        }
    }
    return state;
};

const expireIn = (reducerKey, config = {}) => {
    const _default = {
        expirationKey: '_default_expiration_key',
        expireSeconds: null,
        defaultState: {},
        autoExpire: false,
    };
    config = {..._default, ...config};
    return createTransform(
        (inboundState, key) => persistence(inboundState, key, config),
        (outboundState, key) => rehydrate(outboundState, key, config),
        {
            whitelist: [reducerKey]
        }
    )
};

export default expireIn;