import {handleActions} from "redux-actions";
import actions from "../actions/id.score";
import {API_END, API_START} from "../constants/api.constants";
import _ from "lodash";

export function getDefaults() {
    return {
        previousDetails: {},
        isLoading: false,
        errors: {},
        message: null,
    }
}

export default handleActions(
    {
        [actions.fetch]: (state, action) => {
            switch (action.payload) {
                case API_START:
                    return {
                        ...state,
                        ...getDefaults(),
                        isLoading: true
                    };
                case API_END:
                    return {
                        ...state,
                        isLoading: false
                    };
                default:
                    return state;
            }
        },
        [actions.get_succeeded]: (state, action) => {
            const {idScoreAttempts, product, ...rest} = action.payload;

            return {
                ...state,
                previousDetails: rest,
                idScoreAttempts,
                product
            };
        },
        [actions.submit_succeeded]: (state, action) => {
            return {
                ...state,
                ...action.payload
            };
        },
        [actions.failed]: (state, action) => {
            const errors = _.get(action, "payload.data.errors");

            return {
                ...state,
                errors: !_.isEmpty(errors)
                    ? errors
                    : _.get(action, "payload.data.message"),
            };
        },
        [actions.reset]: () => getDefaults()
    },
    getDefaults()
);
