import { API_START, API_END, ACCESS_DENIED, API_ERROR, API_SUCCESS, API } from '../constants/api.constants';
import config from '../../config';

export const apiStart = label => {
  return ({
    type: API_START,
    payload: label,
  });
};

export const apiSuccess = label => ({
  type: API_SUCCESS,
  payload: label,
});

export const apiEnd = label => ({
  type: API_END,
  payload: label,
});

export const accessDenied = url => ({
  type: ACCESS_DENIED,
  payload: {
    url,
  },
});

export const apiError = error => ({
  type: API_ERROR,
  error,
});

export const apiGet = (path = '',
                       data,
                       label,
                       onSuccess = () => ({}),
                       onFailure = () => ({}),
                       headers = null
  //todo accessToken, headers, headersOverride
) => apiRequest({
  url: `${config.backend_url}/${path}`,
  method: 'GET',
  data,
  label,
  onSuccess,
  onFailure,
  headers
});

export const apiPost = (path = '',
                        data,
                        label,
                        onSuccess = () => ({}),
                        onFailure = () => ({}),
                        headers = null
  //todo accessToken, headers, headersOverride
) => {
  return apiRequest({
    url: `${config.backend_url}/${path}`,
    method: 'POST',
    data,
    label,
    onSuccess,
    onFailure,
    headers
  });
};

export const apiDelete = (
    path = '',
    data,
    label,
    onSuccess = () => ({}),
    onFailure = () => ({}),
    headers = null,
    //todo accessToken, headers, headersOverride
) => apiRequest({
  url: `${config.backend_url}/${path}`,
  method: 'DELETE',
  data,
  headers,
  label,
  onSuccess,
  onFailure,
});

export const apiRequest = ({
                             url = '',
                             method = '',
                             data = null,
                             accessToken = null,
                             onSuccess = () => {
                             },
                             onFailure = () => {
                             },
  label = '',
  headers = null,
  headersOverride = null,
}) => {
  return {
    type: API,
    payload: {
      url,
      method,
      data,
      accessToken,
      onSuccess,
      onFailure,
      label,
      headers,
      headersOverride,
    },
  };
};