import {apiPost, apiGet} from "./api";

const getIsAdminSchema = (url_prefix = '') => url_prefix.startsWith('admin') ? {adminSchema: true} : {};

const send = (action, product, uuid, response) => ({
    type: action,
    product,
    uuid,
    payload: response,
})

const succeeded = (action, product, uuid, response, prev = []) => {
    return {
        type: action,
        product,
        uuid,
        payload: response,
        prev,
    };
};

const failed = (action, product, uuid, response) => {
    return {
        type: action,
        product,
        uuid,
        payload: response,
    };
};

export const commonGetNextQuestion = (action, success, failure) => (url_prefix = '') => (product, data) => {
    const {uuid} = data;
    return apiPost(
        `${url_prefix}step/next`,
        {...data, product, ...getIsAdminSchema(url_prefix)},
        (res) => send(action, product, uuid, res),
        (res) => succeeded(success, product, uuid, res, [{...data}]),
        (res) => failed(failure, product, uuid, res)
    );
};
export const commonGetPrevQuestion = (action, success, failure) => (url_prefix = '') => (product, data) => {
    const {uuid} = data;
    return apiPost(
        `${url_prefix}step/prev`,
        {...data, product, ...getIsAdminSchema(url_prefix)},
        (res) => send(action, product, uuid, res),
        (res) => succeeded(success, product, uuid, res),
        (res) => failed(failure, product, uuid, res)
    );
};

export const commonGetCurrentQuestion = (action, success, failure) => (url_prefix = '') => (product, data) => {
    const {uuid} = data;
    return apiPost(
        `${url_prefix}step`,
        {...data, product, ...getIsAdminSchema(url_prefix)},
        (res) => send(action, product, uuid, res),
        (res) => succeeded(success, product, uuid, res),
        (res) => failed(failure, product, uuid, res)
    );
};
export const commonGetQuestion = (action, success, failure) => (url_prefix = '') => (product, data) => {
    const {uuid} = data;
    return apiGet(
        `${url_prefix}step`,
        {...data, product, ...getIsAdminSchema(url_prefix)},
        (res) => send(action, product, uuid, res),
        (res) => succeeded(success, product, uuid, res),
        (res) => failed(failure, product, uuid, res)
    );
};
export const commonReset = (action) => (product, uuid) => {
    return {
        type: action,
        product,
        uuid,
    };
};

export const commonGetQuoteUUID = (action, success, failure) => (url_prefix = '') => (product, data) => {
    const {uuid} = data;
    return apiGet(
        `${url_prefix}step/quote-exists`,
        {...data, product},
        (res) => send(action, product, uuid, res),
        (res) => succeeded(success, product, uuid, res),
        (res) => failed(failure, product, uuid, res)
    );
};
