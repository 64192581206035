import {API_END, API_START} from "../constants/api.constants";
import moment from "moment";

import actions from '../actions/van.schema';
import {handleActions} from 'redux-actions';
import _ from 'lodash';

export default handleActions({
    [actions.schema]: (state, action) => {
        switch (action.payload) {
            case API_START:
                return {...state, isLoading: true, formErrors: {}};
            case API_END:
                return {...state, isLoading: false};
            default:
                return state;
        }
    },
    [actions.schema_succeeded]: (state, action) => {
        return {
            ...state,
            schema: {...action.payload},
            formErrors: {},
            persistExpiresAt: moment().add(7, 'd').toDate(),
        }
    },
    [actions.schema_failed]: (state, action) => {
        const errors = _.get(action, 'payload.data.errors');
        return {
            ...state,
            formErrors: !_.isEmpty(errors) ? errors : _.get(action, 'payload.data.message') || 'Please try later',
            persistExpiresAt: moment().add(7, 'd').toDate(),
        }
    },
    [actions.schema_reset]: () => {
        return getDefaults()
    }
}, getDefaults());

export function getDefaults() {
    return {
        schema: {},
        isLoading: false,
        formErrors: {},
        persistExpiresAt: null
    }
}
