import {handleActions} from 'redux-actions'
import actions from '../actions/lookup'
import {API_END, API_START} from '../constants/api.constants'

import _ from 'lodash'

export default handleActions(
  {
    [actions.search]: (state, action) => {
      switch (action.payload) {
        case API_START:
          return {
            ...state,
            direction: {
              ...state.direction,
              isLoading: true,
              postcodes: [],
              errors: {},
              address: {}
            }
          }
        case API_END:
          return {...state, direction: {...state.direction, isLoading: false}}
        default:
          return state
      }
    },
    [actions.select]: (state, action) => {
      switch (action.payload) {
        case API_START:
          return {
            ...state,
            direction: {
              ...state.direction,
              isLoading: true,
              address: {},
              errors: {}
            }
          }
        case API_END:
          return {...state, direction: {...state.direction, isLoading: false}}
        default:
          return state
      }
    },
    [actions.search_succeeded]: (state, action) => {
      return {
        ...state,
        direction: {
          ...state.direction,
          postcodes: [...action.payload],
          address: {}
        }
      }
    },
    [actions.select_succeeded]: (state, action) => {
      return {
        ...state,
        direction: {
          ...state.direction,
          address: {
            ...action.payload
          }
        }
      }
    },
    [actions.address_failed]: (state, action) => {
      const errors = _.get(action, 'payload.data.errors')
      return {
        ...state,
        direction: {
          ...state.direction,
          errors: !_.isEmpty(errors)
            ? errors
            : _.get(action, 'payload.data.message') ||
              'Network error, please try later.'
        }
      }
    },
    [actions.reset_address]: (state, action) => {
      return {
        ...state,
        direction: getDefaults().direction
      }
    },
    [actions.cdl]: (state, action) => {
      switch (action.payload) {
        case API_START:
          return {
            ...state,
            vehicle: {
              cdl: {},
              makes: {},
              models: {},
              years: {},
              specs: {},
              bodyTypes: {},
              fuelTypes: {},
              transmissions: {},
              isLoading: true,
              errors: {}
            }
          }
        case API_END:
          return {...state, vehicle: {...state.vehicle, isLoading: false}}
        default:
          return state
      }
    },
    [actions.make]: (state, action) => {
      switch (action.payload) {
        case API_START:
          return {
            ...state,
            vehicle: {
              makes: {},
              models: {},
              years: {},
              specs: {},
              bodyTypes: {},
              fuelTypes: {},
              transmissions: {},
              isLoading: true,
              errors: {}
            }
          }
        case API_END:
          return {...state, vehicle: {...state.vehicle, isLoading: false}}
        default:
          return state
      }
    },
    [actions.model]: (state, action) => {
      switch (action.payload) {
        case API_START:
          return {
            ...state,
            vehicle: {
              ...state.vehicle,
              models: {},
              years: {},
              specs: {},
              bodyTypes: {},
              fuelTypes: {},
              transmissions: {},
              isLoading: true,
              errors: {}
            }
          }
        case API_END:
          return {...state, vehicle: {...state.vehicle, isLoading: false}}
        default:
          return state
      }
    },
    [actions.year]: (state, action) => {
      switch (action.payload) {
        case API_START:
          return {
            ...state,
            vehicle: {
              ...state.vehicle,
              years: {},
              specs: {},
              bodyTypes: {},
              fuelTypes: {},
              transmissions: {},
              isLoading: true,
              errors: {}
            }
          }
        case API_END:
          return {...state, vehicle: {...state.vehicle, isLoading: false}}
        default:
          return state
      }
    },
    [actions.body_type]: (state, action) => {
      switch (action.payload) {
        case API_START:
          return {
            ...state,
            vehicle: {
              ...state.vehicle,
              specs: {},
              bodyTypes: {},
              fuelTypes: {},
              transmissions: {},
              isLoading: true,
              errors: {}
            }
          }
        case API_END:
          return {...state, vehicle: {...state.vehicle, isLoading: false}}
        default:
          return state
      }
    },
    [actions.transmission]: (state, action) => {
      switch (action.payload) {
        case API_START:
          return {
            ...state,
            vehicle: {
              ...state.vehicle,
              specs: {},
              fuelTypes: {},
              transmissions: {},
              isLoading: true,
              errors: {}
            }
          }
        case API_END:
          return {...state, vehicle: {...state.vehicle, isLoading: false}}
        default:
          return state
      }
    },
    [actions.fuel_type]: (state, action) => {
      switch (action.payload) {
        case API_START:
          return {
            ...state,
            vehicle: {
              ...state.vehicle,
              specs: {},
              fuelTypes: {},
              isLoading: true,
              errors: {}
            }
          }
        case API_END:
          return {...state, vehicle: {...state.vehicle, isLoading: false}}
        default:
          return state
      }
    },
    [actions.spec]: (state, action) => {
      switch (action.payload) {
        case API_START:
          return {
            ...state,
            vehicle: {
              ...state.vehicle,
              spec: {},
              isLoading: true,
              errors: {}
            }
          }
        case API_END:
          return {...state, vehicle: {...state.vehicle, isLoading: false}}
        default:
          return state
      }
    },
    [actions.vehicle_succeeded]: (state, action) => {
      return {
        ...state,
        vehicle: {...state.vehicle, ...action.payload}
      }
    },
    [actions.vehicle_failed]: (state, action) => {
      const errors = _.get(action, 'payload.data.errors')
      return {
        ...state,
        vehicle: {
          ...state.vehicle,
          errors: !_.isEmpty(errors)
            ? errors
            : _.get(action, 'payload.data.message') ||
              'Network error, please try later.'
        }
      }
    },
    [actions.vehicle_reset]: (state, action) => {
      return {
        ...state,
        vehicle: getDefaults().vehicle
      }
    }
  },
  getDefaults()
)

export function getDefaults() {
  return {
    direction: {
      isLoading: false,
      errors: {},
      postcodes: [],
      test: [],
      address: {}
    },
    vehicle: {
      cdl: {},
      makes: {},
      models: {},
      years: {},
      bodyTypes: {},
      transmissions: {},
      fuelTypes: {},
      specs: {},
      isLoading: false,
      errors: {}
    }
  }
}
