import { handleActions } from "redux-actions";
import actions from "../actions/admin.quote";
import { API_END, API_START } from "../constants/api.constants";

import _ from "lodash";

export default handleActions(
  {
    [actions.get_quotes]: (state, action) => {
      switch (action.payload) {
        case API_START:
          return {
            ...state,
            isLoading: true,
            errors: {},
            quotes: [],
          };
        case API_END:
          return { ...state, isLoading: false };
        default:
          return state;
      }
    },
    [actions.quote_succeeded]: (state, action) => {
      const quote = _.get(state, action.payload.uuid, {});
      const discounts = _.get(action, "payload.discounts", {});

      return {
        ...state,
        [action.payload.uuid]: {
          ...quote,
          ...action.payload,
        },
        discounts: {
          ...state.discounts,
          ...discounts,
        },
      };
    },
    [actions.fetching_update_state]: (state, action) => {
      switch (action.payload) {
        case API_START:
          return {
            ...state,
            isLoading: true,
          };
        case API_END:
          return { ...state, isLoading: false };
        default:
          return state;
      }
    },
    [actions.update_state_succeeded]: (state, action) => {
      const uuid = _.get(action, "payload.uuid", "");
      const _quotes = _.get(state, "quotes.quotes", []);

      const quotes = _quotes.map((q) => {
        if (q.uuid === uuid) {
          return {
            ...q,
            state: action.payload.state,
            updatedAt: action.payload.updatedAt,
          };
        }
        return q;
      });
      return {
        ...state,
        quotes: {
          ...state.quotes,
          quotes: _.orderBy(quotes, ["updatedAt"], ["desc"]),
        },
      };
    },
    [actions.update_state_failed]: (state, action) => {
      const _errors = _.get(action, "payload.data.errors", "");

      return {
        ...state,
        errors: !_.isEmpty(_errors)
          ? _errors
          : "unable to change payment state",
      };
    },
    [actions.get_quote_list_succeeded]: (state, action) => {
      const failReason = _.get(action, "payload.fail_reason", "");
      const totalItems = _.get(action, "payload.count", 0);
      const quote_state = _.get(action, "payload.quotes.0.state", "");

      return {
        ...state,
        quotes: action.payload,
        totalItems,
        failReason,
        quote_state,
      };
    },
    [actions.quote_failed]: (state, action) => {
      const errors = _.get(action, "payload.data.errors");
      return {
        ...state,
        errors: !_.isEmpty(errors)
          ? errors
          : _.get(action, "payload.data.message") || "Please try later",
      };
    },
    [actions.send_discount]: (state, action) => {
      switch (action.payload) {
        case API_START:
          return {
            ...state,
            discounts: {
              ...getDefaults().discounts,
              isLoading: true,
            },
          };
        case API_END:
          return {
            ...state,
            discounts: {
              ...state.discounts,
              isLoading: false,
            },
          };
        default:
          return state;
      }
    },
    [actions.discount_failed]: (state, action) => {
      const _errors = _.get(action, "payload.data.errors");

      const errors = !_.isEmpty(_errors)
        ? _errors
        : _.get(action, "payload.data.message") || "Please try later";
      return {
        ...state,
        discounts: {
          ...state.discounts,
          errors,
        },
      };
    },
    [actions.reset_discount]: (state) => {
      return {
        ...state,
        discounts: getDefaults().discounts,
      };
    },
    [actions.requote]: (state, action) => {
      switch (action.payload) {
        case API_START:
          return {
            ...state,
            isLoading: true,
            errors: {},
          };
        case API_END:
          return { ...state, isLoading: false };
        default:
          return state;
      }
    },
    [actions.requote_succeeded]: (state, action) => {
      const uuid = _.get(action, 'payload.leadUUID', '');
      const {product} = action.payload
      const redirect_path = `/admin/step/${product}/${uuid}`;
      return {
        ...state,
        ...action.payload,
        redirect_path
      };
    },
    [actions.requote_failed]: (state, action) => {
      const errors = _.get(action, "payload.data.errors");
      return {
        ...state,
        errors: !_.isEmpty(errors)
            ? errors
            : _.get(action, "payload.data.message") || "Please try later",
      };
    },
  },
  getDefaults()
);

export function getDefaults() {
  return {
    isLoading: false,
    discounts: {
      errors: {},
      message: "",
      isLoading: false,
    },
    errors: {},
  };
}
