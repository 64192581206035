import {useLocation} from "react-router-dom";
import {useEffect} from "react";
import { animateScroll as scroll } from 'react-scroll'

export const ScrollToTop = () => {
    const {pathname} = useLocation()
    useEffect(()=>{
        scroll.scrollToTop()
    }, [pathname])

    return null
}