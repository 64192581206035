import {
    API_START,
    API_END
} from '../constants/api.constants';
import moment from 'moment';

import config from '../../config'
import {handleActions} from 'redux-actions';
import actions from '../actions/van';


import _ from "lodash";

export const getDefaults = () => {
    return {
        initialValues: config.initialValues,
        current: 'you',
        nextStep: false,
        errors: {}
    }
};

export default handleActions({
    [actions.van_validate]: (state, action) => {
        switch (action.payload) {
            case API_START:
                return {...state, isLoading: true, errors: {}};
            case API_END:
                return {...state, isLoading: false};
            default:
                return state;
        }
    },
    [actions.van_send_quote]: (state, action) => {
        switch (action.payload) {
            case API_START:
                return {...state, isLoading: true, errors: {}};
            case API_END:
                return {...state, isLoading: false};
            default:
                return state;
        }
    },
    [actions.van_add_data]: (state, action) => {
        return {
            ...state,
            persistExpiresAt: moment().add(7, 'd').toDate(),
            nextStep: true,
            initialValues: {
                ...state.initialValues, ...action.payload,
            }
        };
    },
    [actions.van_remove_data]: (state, action) => {
        const {property, index} = action.payload;
        const data = state.initialValues[property];
        return {
            ...state, persistExpiresAt: moment().add(7, 'd').toDate(),
            initialValues: {
                ...state.initialValues,
                [property]: [...data.slice(0, index), ...data.slice(index + 1)],

            }
        }
    },
    [actions.van_succeeded]: (state, action) => {
        return {
            ...state, nextStep: true,
        }
    },
    [actions.van_failed]: (state, action) => {
        const errors = _.get(action, 'payload.data.errors');
        return {
            ...state,
            errors: !_.isEmpty(errors) ? errors : _.get(action, 'payload.data.message') || 'Please try later'
        }
    },
    [actions.go_to]: (state, action) => {
        const {steps, current, to} = action.payload;
        if (_.findIndex(steps, i => i.key === current) >=
            _.findIndex(steps, i => i.key === to)) {
            return {
                ...state, ...{
                    current: to,
                }
            };
        }
        return state
    },
    [actions.set_next_step]: (state, action) => {
        return {
            ...state, nextStep: action.payload
        };
    },
    [actions.go_to_next]: (state, action) => {
        const next = action.payload.steps[_.findIndex(action.payload.steps, i => i.key === action.payload.current) + 1];
        return {
            ...state, ...{
                current: next.key,
                nextStep: false
            }
        }
    },
    [actions.van_reset]: (state, action) => {
        return getDefaults()
    }
}, getDefaults());
