import {createAction} from 'redux-actions';
import {apiPost} from './api';

const actions = {
  forward_granite: createAction('ADMIN_FORWARD_GRANITE'),
  forward_granite_failed: createAction('ADMIN_FORWARD_GRANITE_FAILED'),
  forward_granite_succeeded: createAction('ADMIN_FORWARD_GRANITE_SUCCEEDED'),
};
export default actions;

export const forwardGranite = (data) => apiPost('admin/forward/granite', data, actions.forward_granite, actions.forward_granite_succeeded, actions.forward_granite_failed);

