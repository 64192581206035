const redirect = {
    // to be used in a reducer
    add: (to) => ({redirect_path: to}),
    // to be used in a react component
    handle: (props = {}, base_path = '') => {
        const {redirect_path, history} = props,
            {pathname} = props.location;
        if (!redirect_path) return;
        // check current path and redirect only if different
        if (redirect_path && redirect_path !== pathname) {
            history.push(base_path ? `${base_path}${redirect_path}` : redirect_path);
        }

    }
};
export default redirect;