import React from 'react';
import PropTypes from 'prop-types';
import {FormFeedback, Label, FormGroup} from 'reactstrap';
import {Field, useFormikContext} from 'formik';
import _ from 'lodash';

/*Fields*/
import TextInput from "./text.input";
import DatePicker from "./date.picker";
import DropDownDatePicker from "./dropdown.date.picker";
import RadioButton from "./radio.button";
import Select from "./select";
import AutoSuggest from "./auto.suggest";

/*Formik Fields*/
export const FieldSetCheckBox = ({name, label, link, linkText, error = {}}) => {
    const err = error[name] || null;

    return (
        <FormGroup check>
            <Label check className={` ${!_.isEmpty(err) ? 'is-invalid' : ''} `}>
                <Field type="checkbox" name={name} className='form-check-input'/>
                {label}
            </Label>
            {link && <span>{' '}<a target={'_black'} href={link}>{linkText}</a></span>}
            {err && (
                <FormFeedback style={{display: err ? 'block' : 'none'}} valid={_.isEmpty(err)}>{err}</FormFeedback>)}
        </FormGroup>
    );
};
export const FieldSet = ({name, label, readonly, error = {}, type, placeholder, autocomplete, handleOnBlur, children, handleOnChange, innerRef, step}) => {

    return (
        <Field name={name} type={type}>
            {({field}) => {
                const err = error[name] || null;
                return <TextInput {...{name, type, label, readonly, error: err, placeholder, autocomplete, handleOnChange, handleOnBlur, children, innerRef, field, step}}/>
            }}
        </Field>
    );
};

export const FieldSetAutoSuggest = ({name, label, placeholder, list, autocomplete, error = {}, innerRef}) => {

    return (
        <Field name={name}>
            {({field}) => {
                let err = error[field.name] || null;
                return (
                    <AutoSuggest {...{
                        ...field,
                        options: list,
                        handleOnChange: value => field.onChange(name)(value),
                        innerRef,
                        name: field.name,
                        placeholder,
                        error: err,
                        label,
                        autocomplete
                    }}
                    />
                );
            }}
        </Field>

    );
};

export const FieldSetDatePicker = ({name, label, placeholder, autocomplete, error = {}, handleOnBlur, innerRef, minDate, maxDate, showYearDropdown, yearDropdownItemNumber}) => {
    return (
        <Field name={name}>
            {({field}) => {
                let err = error[field.name] || null;
                return (
                    <DatePicker {...{
                        ...field,
                        label,
                        name,
                        placeholder,
                        error: err,
                        autocomplete,
                        showYearDropdown,
                        minDate,
                        maxDate,
                        innerRef,
                        handleOnBlur,
                        yearDropdownItemNumber,
                        onDateChange: date => field.onChange(name)(date)
                    }}/>


                );
            }}
        </Field>
    );
};
export const FieldSetDropDownDatePicker = ({name, label, styles, classess, error = {}, innerRef, displayMonthName, startYear, endYear}) => {
    return (
        <Field name={name}>
            {({field}) => {
                let err = error[field.name] || null;
                return (
                    <DropDownDatePicker {...{
                        ...field,
                        styles,
                        classess,
                        label,
                        name,
                        selectedDate: field.value,
                        displayMonthName,
                        error: err,
                        startYear: startYear || null,
                        endYear: endYear || null,
                        innerRef,
                        onDateChange: date => field.onChange(name)(date)
                    }}/>


                );
            }}
        </Field>
    );
};
export const FieldSetSelect = ({name, label, error = {}, placeholder, autocomplete: autoComplete, handleOnBlur, options = [], innerRef, optGroup = false}) => {
    return (
        <Field name={name} innerRef={innerRef}>
            {({field}) => {
                const err = error[field.name] || null;
                return <Select {...{
                    label,
                    name,
                    innerRef,
                    autoComplete,
                    placeholder,
                    error: err,
                    options,
                    field,
                    handleOnBlur,
                    optGroup,
                    handleOnChange: val => field.onChange(name)(val)
                }}/>
            }}
        </Field>
    )
};

export const FieldSetRadioButton = ({name, label, value, error = {}, innerRef, checked, styles}) => {
    const {setFieldValue} = useFormikContext();
    const handleOnChange = val => setFieldValue(name, val);
    return (
        <Field name={name}>
            {({field}) => <RadioButton {...{
                label,
                name,
                value,
                onChange: handleOnChange,
                checked: checked || field.value === value,
                innerRef,
                error: error[field.name] || null,
                styles
            }}/>}
        </Field>
    )
};

export const FieldSetRadioGroup = ({label, name, options, extraOptions = [], innerRef, error = {}, otherKey, styles}) => {
    const {values} = useFormikContext();
    const fieldValue = _.get(values, name, '');
    const [isIn, setIsIn] = React.useState(fieldValue === otherKey);

    React.useEffect(() => {
        if (extraOptions) {
            const valueIsInOtherOptions = extraOptions.some(opt => opt.key === fieldValue);
            setIsIn(valueIsInOtherOptions || fieldValue === otherKey);
        }
    }, [setIsIn, otherKey, extraOptions, fieldValue]);

    const err = error[name] || null;
    return (
        <React.Fragment>
            {label && <Label className={` ${!_.isEmpty(err) ? 'is-invalid' : ''} `}>{label}</Label>}
            <ul className="radio-group-wrapper mb-0">
                {options.map(option => (
                    <li key={option.key}>
                        <FieldSetRadioButton {...{label: option.value, name, value: option.key, innerRef, error, styles}} />
                    </li>
                ))}
            </ul>
            {isIn && !_.isEmpty(extraOptions) && (
                <FieldSetSelect
                    {...{
                        label: '',
                        type: 'select',
                        hideLabel: true,
                        options: extraOptions,
                        name,
                        error,
                        innerRef
                    }}
                />
            )}
            {err && !isIn && (<FormFeedback className={'mt-0'} style={{display: err ? 'block' : 'none'}}
                                            valid={_.isEmpty(err)}>{err}</FormFeedback>)}
        </React.Fragment>
    );
};

FieldSetCheckBox.propType = {
    name: PropTypes.string.required,
    label: PropTypes.string,
    link: PropTypes.string, linkText: PropTypes.string,
    error: PropTypes.object
};

FieldSetDropDownDatePicker.propTypes = {
    label: PropTypes.string,
    startYear: PropTypes.string,
    endYear: PropTypes.string,
    displayMonthName: PropTypes.bool,
    name: PropTypes.string,
    error: PropTypes.object,
    classes: PropTypes.shape({
        wrapper: PropTypes.string,
        day: PropTypes.string,
        month: PropTypes.string,
        year: PropTypes.string
    }),
    styles: PropTypes.shape({
        day: PropTypes.object,
        month: PropTypes.object,
        year: PropTypes.object
    }),
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({current: PropTypes.any})
    ])
};
FieldSetRadioGroup.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.required, value: PropTypes.string.required,
    })),
    extraOptions: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.required, value: PropTypes.string.required,
    })),
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({current: PropTypes.any})
    ]),
    error: PropTypes.object,
    otherKey: PropTypes.string,
    styles: PropTypes.object
};

FieldSetSelect.propType = {
    name: PropTypes.string.required,
    label: PropTypes.string,
    error: PropTypes.object,
    placeholder: PropTypes.string,
    autocomplete: PropTypes.string,
    handleOnBlur: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.required, value: PropTypes.string.required,
    })),
    optGroup: PropTypes.bool,
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({current: PropTypes.any})
    ])
};

FieldSetAutoSuggest.propType = {
    name: PropTypes.string.required,
    label: PropTypes.string,
    error: PropTypes.object,
    placeholder: PropTypes.string,
    list: PropTypes.objectOf(PropTypes.string),
    autocomplete: PropTypes.string,
    handleOnBlur: PropTypes.func,
};

FieldSetDatePicker.propType = {
    name: PropTypes.string.required,
    label: PropTypes.string,
    error: PropTypes.object,
    placeholder: PropTypes.string,
    autocomplete: PropTypes.string,
    handleOnBlur: PropTypes.func,
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({current: PropTypes.any})
    ]),
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
    showYearDropdown: PropTypes.bool,
    yearDropdownItemNumber: PropTypes.number

};

FieldSet.propType = {
    name: PropTypes.string.required,
    label: PropTypes.string,
    error: PropTypes.object,
    type: PropTypes.string.required,
    placeholder: PropTypes.string,
    autocomplete: PropTypes.string,
    handleOnBlur: PropTypes.func,
    children: PropTypes.node,
    step: PropTypes.string,
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({current: PropTypes.any})
    ])
};

