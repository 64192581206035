import React from 'react';
import {Container, Row} from 'reactstrap';
import img_irev_logo from '../images/logo-irev.svg';
import img_5star_logo from '../images/logo-5star.svg';
import img_wd_logo from '../images/logo-wd.svg';
import img_mastercard_logo from '../images/mastercard.svg';
import img_maestro_logo from '../images/maestro.svg';
import img_jcb_logo from '../images/jcb.svg';
import img_visa_logo from '../images/visa.svg';
import img_credit_dd from '../images/payment-dd.png';
import config from "../config";

export default class Footer extends React.Component {

    render() {
        return (
            <footer>
                <Container>
                    <div className="row">
                        <div className="col-sm-8 offset-sm-2">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="h4">Contact Us</div>
                                    <p>
                                        Need to speak to us?<br/>
                                        0330 808 1500
                                    </p>

                                    <p>
                                        Opening Hours:<br/>
                                        {config.timeTable.week.text}<br/>
                                        {config.timeTable.saturday.text}<br/>
                                        Sunday: Closed
                                    </p>
                                </div>

                                <div className="col-sm-6">
                                    <div className="h4">
                                        FCA Registered
                                    </div>
                                    <p>
                                        Insurance Revolution Broking Services &amp; Well Dunn Insurance Revolution
                                        Services are trading names of Well Dunn Limited.
                                        Authorised and regulated by the Financial Conduct Authority. Ref 582576.
                                        Registered Office: Unit 5, Blantyre Street, Manchester M15 4JJ.
                                        Registered in England. Number 7918469.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>

                <hr/>

                <Container>
                    <Row className="d-flex justify-content-between align-items-center">
                        <div className="col-sm-4">
                            <img className="img-fluid" src={img_irev_logo} alt="insurance revolution"/>
                        </div>
                        <div className="col-sm-4">
                            <img className="img-fluid" src={img_wd_logo} alt="well dunn"/>
                        </div>
                        <div className="col-sm-4">
                            <img className="img-fluid" src={img_5star_logo} alt="5 star cover"/>
                        </div>
                    </Row>
                </Container>
                <hr/>

                <Container>
                    <Row className="d-flex justify-content-between align-items-center">
                        <div className="col-md-2 col-sm-4">
                            <img className="img-fluid" src={img_mastercard_logo} alt="master card"/>
                        </div>
                        <div className="col-md-2 col-sm-4">
                            <img className="img-fluid" src={img_visa_logo} alt="visa"/>
                        </div>
                        <div className="col-md-2 col-sm-4">
                            <img className="img-fluid" src={img_maestro_logo} alt="maestro"/>
                        </div>
                        <div className="col-md-2 col-sm-4">
                            <img className="img-fluid" src={img_jcb_logo} alt="jcb"/>
                        </div>
                        <div className="col-md-2 col-sm-4">
                            <img className="img-fluid" src={img_credit_dd} alt="direct debit"/>
                        </div>
                    </Row>
                </Container>

                <hr/>
                <Container>

                    <div className="text-center">
                        <a className="btn btn-link btn-sm" href="https://www.insurancerevolution.co.uk/toba/"
                           target="_blank" rel={'noopener noreferrer'}>Terms of Business Agreement</a>

                        <a className="btn btn-link btn-sm" href="https://www.insurancerevolution.co.uk/toba/"
                           target="_blank" rel={'noopener noreferrer'}>Refund Policy</a>

                        <a className="btn btn-link btn-sm" href="/#/unsubscribe/email" target="_blank">Unsubscribe</a>

                        <a className="btn btn-link btn-sm" href="/#/unsubscribe/telephone" target="_blank">Opt-out</a>
                    </div>
                </Container>

            </footer>
        );
    }
}
