import _ from "lodash";
import {createStore, compose, applyMiddleware, combineReducers} from "redux";
import thunk from "redux-thunk";

import apiMiddleware from "./middleware/api.middleware";
import {persistStore, persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import expireIn from "./transform.expire.reducers";

import vanReducer from "./reducers/van";
import smsReducer from "./reducers/sms";
import buyReducer from "./reducers/buy";
import vanSchemaReducer from "./reducers/van.schema";
import bankHolidaysReducer from "./reducers/bank.holiday";
import adminReducer from "./reducers/admin";
import adminForwardReducer from "./reducers/admin.forward";
import quoteReducer from "./reducers/quote";
import adminQuoteReducer from "./reducers/admin.quote";
import adminLeadsReducer from "./reducers/admin.leads";
import lookupReducer from "./reducers/lookup";
import idScoreReducer from "./reducers/id.score";
import unsubscribeReducer from "./reducers/unsubscribe";
import userReducer, {getDefaults as getUserDefaults} from "./reducers/user";
import {products} from "../utils/products";
import {getDefaults} from "./reducers/step";

import stepReducer from "../redux/reducers/step";
import adminStepReducer from "../redux/reducers/admin.step";
import additionalQuoteDetailsReducer from "./reducers/additional.quote.details";

const initialState = {};

const middlewares = [thunk, apiMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//get step reducers for any product
const getAdminStepReducers = () =>
    _.reduce(
        products,
        (acc, product) => ({
            ...acc,
            [`admin_step_${product}`]: adminStepReducer(product),
        }),
        {}
    );
const getStepReducers = () =>
    _.reduce(
        products,
        (acc, product) => ({...acc, [product]: stepReducer(product)}),
        {}
    );

const rootReducer = combineReducers({
    van: vanReducer,
    buy: buyReducer,
    bankHolidays: bankHolidaysReducer,
    ...getStepReducers(),
    ...getAdminStepReducers(),
    vanSchema: vanSchemaReducer,
    admin: adminReducer,
    quote: quoteReducer,
    adminQuote: adminQuoteReducer,
    adminLeads: adminLeadsReducer,
    adminForward: adminForwardReducer,
    lookup: lookupReducer,
    sms: smsReducer,
    idScore: idScoreReducer,
    unsubscribe: unsubscribeReducer,
    additionalQuoteDetails: additionalQuoteDetailsReducer,
    user: userReducer,
});

const _transforms = () => {
    const transformProducts = _.map(products, (reducerKey) =>
        expireIn(reducerKey, {
            expireSeconds: 60 * 60 * 24 * 7,
            autoExpire: true,
            defaultState: getDefaults(),
        })
    );

    const transformUser = expireIn('user', {
        expireSeconds: 30,
        autoExpire: true,
        defaultState: getUserDefaults(),
    })

    return [...transformProducts, transformUser]
}
const _whitelist = () => _.map(products, (reducerKey) => reducerKey);

const persistedReducers = persistReducer(
    {
        key: "iros-marketplace",
        storage,
        transforms: [..._transforms()],
        whitelist: [..._whitelist(), "admin", "user"],
    },
    rootReducer
);

const store = createStore(
    persistedReducers,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
);

//clear van from local storage
function clearStorage() {
    const storageKey = "persist:iros-marketplace";
    const state = store.getState();
    const _products = Object.values(products);

    let storage = JSON.parse(localStorage.getItem(storageKey));

    _.forEach(state, (v, reducerKey) => {
        const reducer = state[reducerKey];
        if (
            _products.includes(reducerKey) &&
            reducer.question &&
            reducer.question.key === "end"
        ) {
            store.dispatch({
                product: reducerKey,
                type: "STEP_RESET",
            });
            storage = Object.keys(storage)
                .filter((key) => key !== reducerKey)
                .reduce((res, key) => Object.assign(res, {[key]: storage[key]}), {});
        }
    });

    localStorage.setItem(storageKey, JSON.stringify(storage));
}

const persistor = persistStore(store);

export {store, persistor, clearStorage};
