import {API_END, API_START} from "../constants/api.constants";
import _ from "lodash";
import redirect from "../../utils/redirect";

const updateUuidObject = (
    _state,
    action,
    new_data,
    product = null,
    _uuid = null
) => {
    const state = {..._state};
    const uuid = _.get(action, "payload.data.uuid", "") || _uuid;
    if (!uuid || !product) return state;
    if (!state[uuid]) state[uuid] = {};
    state[uuid] = {...state[uuid], ...new_data};
    return state;
};

const updateQuestionSet = (state, action, uuid) => {
    const questionSet = _.get(
        state[uuid],
        "questionSet",
        _.get(action, "payload.questionSet", [])
    );
    let curQuestion = _.get(action, "prev", []);
    if (
        _.get(action, "payload.question.key", "") ===
        _.get(questionSet[questionSet.length - 1], "key") &&
        questionSet
    ) {
        return questionSet.slice(0, -1);
    }
    return questionSet.concat(curQuestion);
};

export const actionGetQuestion = (state, action, product) => {
    const {uuid} = action;
    switch (action.payload) {
        case API_START:
            return updateUuidObject(
                state,
                action,
                {isLoading: true, errors: {}},
                product,
                uuid
            );
        case API_END:
            return updateUuidObject(
                state,
                action,
                {isLoading: false},
                product,
                uuid
            );
        default:
            return state;
    }
};

export const actionStepSucceeded = (redirect_url_prefix = '') => (state, action, product) => {
    const {uuid} = action;
    const _uuid = _.get(action, "payload.uuid", uuid);
    const quote_uuid = _.get(action, "payload.quote_uuid", "");

    const questionSet = updateQuestionSet(state, action, _uuid);

    const uuid_state = updateUuidObject(
        state,
        action,
        {
            ...action.payload,
            questionSet,
        },
        product,
        _uuid
    );

    return {
        ...state,
        ...uuid_state,
        ...(_uuid ? redirect.add(`${redirect_url_prefix}/step/${product}/${_uuid}`) : {}),
        ...(quote_uuid ? redirect.add(`${redirect_url_prefix}/quote/${quote_uuid}`) : {}),
    };
}
export const actionStepFailed = (state, action, product) => {
    const {uuid} = action;
    const errors = _.get(action, "payload.data.error");
    if (_.has(errors, "question")) delete errors.question;

    const uuid_state = updateUuidObject(
        state,
        action,
        {
            errors: !_.isEmpty(errors)
                ? errors
                : _.get(action, "payload.data.message") || "Please try later",
        },
        product,
        uuid
    );

    return {
        ...state,
        ...uuid_state,
    };
}

export const actionStepReset = (redirect_url_prefix = '') => (state, action) => {
    const {uuid, product} = action;
    const newState = _.keys(state)
        .filter((k) => k !== uuid)
        .reduce((acc, k) => ({...acc, [k]: state[k]}), {});
    return {
        ...newState,
        ...redirect.add(`${redirect_url_prefix}/step/${product}`),
    };
}

export const getDefaults = () => ({})

export const stepReducer = (actionsHandle) => (productType = "") => {
    return function form(state = getDefaults(), action) {
        const {product} = action;

        if (product !== productType) return state;

        if (actionsHandle[action.type])
            return actionsHandle[action.type](state, action, product);

        return state;
    };
};
