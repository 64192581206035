import React, {Suspense, lazy} from "react";
import {Switch, Route, Redirect, withRouter} from "react-router-dom";
import _ from "lodash";

import "./app.scss";

import {products} from "./utils/products";

import Header from "./layout/header";
import Footer from "./layout/footer";
import ApiLead from "./containers/api.lead";
import requiredAuth from "./components/admin/page.authorised";
import IdScoreForm from "./containers/id.score.form.container";
import PageThankYou from "./components/page.thank.you";
import AllowAccessRoute from "./components/allow.access.route";
import {ScrollToTop} from "./components/scroll.to.top";

const Home = lazy(() => import("./containers/home"));
const Buy = lazy(() => import("./containers/buy.container"));

const Admin = lazy(() => import("./containers/admin"));
const Default = lazy(() => import("./components/default"));
const AdminForwardGranite = lazy(() =>
    import("./containers/admin.forward.granite")
);
const Quote = lazy(() => import("./containers/quote"));
const StepForm = lazy(() => import("./containers/step.form"));
const AdminStepForm = lazy(() => import("./containers/admin.step.form"));

const AdminQuotes = lazy(() => import("./containers/admin.quotes"));
const AdminQuote = lazy(() => import("./containers/admin.quote"));
const AdminQuoteDetails = lazy(() =>
    import("./containers/admin.quote.details")
);

const AdditionalQuoteDetails = lazy(() =>
    import("./components/additional-details/licence-number/container.additional.quote.details")
);


const AdminLeads = lazy(() => import("./containers/admin.leads"));

const Unsubscribe = lazy(() => import("./containers/unsubscribe"));

const adminStepFormRoutes = () =>
    _.map(products, (p) => (
        <Route
            exact
            key={`admin-${p}`}
            path={`/admin/step/${p.replace(/_/g, "-")}/:uuid?`}
            component={requiredAuth(AdminStepForm, {product: p})}
        />
    ));
const stepForms = () =>
    _.map(products, (p) => (
        <Route
            exact
            key={p}
            path={`/step/${p.replace(/_/g, "-")}/:uuid?`}
            render={(props) => <StepForm {...{...props, product: p}} />}
        />
    ));

const displayHeader = (path) => {
    const routesWithoutHeader = ["^/buy", "^/quote", "^/additional-details"];
    const regex = new RegExp(routesWithoutHeader.join("|"), "i");
    return path.match(regex) ? null : <Header/>;
};

class App extends React.Component {

    componentDidMount() {
        if (!this.props?.location?.pathname?.startsWith('/admin')) {
            window.loadHotjar();
        }
    }

    render() {
        return (
            <div className="app">
                <ScrollToTop/>
                {displayHeader(this?.props?.location?.pathname)}

                <Suspense
                    fallback={
                        <div className="main-content">
                            <p className="text-center m-5">Please Wait...</p>
                        </div>
                    }
                >
                    <div className="main-content">
                        <Switch>
                            <Route exact path="/" component={Home}/>
                            {/* Admin Pages */}
                            <Route exact path="/admin" component={requiredAuth(Admin)}/>
                            <Route
                                path="/admin/forward/granite"
                                component={requiredAuth(AdminForwardGranite)}
                            />
                            {adminStepFormRoutes()}
                            <Route
                                path="/admin/quotes/:state?"
                                component={requiredAuth(AdminQuotes)}
                            />
                            <Route path="/admin/leads/:uuid?" component={requiredAuth(AdminLeads)}/>
                            <Route
                                path="/admin/quotes/:state?"
                                component={requiredAuth(AdminQuotes)}
                            />
                            {/* Van Pages */}
                            <Route exact path="/fastfood-step">
                                <Redirect to="/step/fastfood"/>
                            </Route>
                            {/*<Route path='/van-step' component={StepForm}/>*/}
                            {/*<Route path='/van' component={VanForm}/>*/}
                            {/*<Route path='/van-schema' component={SchemaForm}/>*/}

                            <Route
                                path="/admin/form/id-score/:uuid"
                                component={requiredAuth(IdScoreForm)}
                            />
                            <Route path="/form/id-score/:uuid" component={IdScoreForm}/>
                            {/* FastFood Pages */}
                            {stepForms()}
                            {/* Checkout Pages  */}
                            <Route path={"/buy/:uuid"} component={Buy}/>
                            <Route path="/admin/buy/:uuid" component={requiredAuth(Buy)}/>

                            <Route path="/additional-details/driver-licence/:quote_uuid"
                                   component={AdditionalQuoteDetails}/>
                            <Route path="/admin/additional-details/driver-licence/:quote_uuid"
                                   component={requiredAuth(AdditionalQuoteDetails)}/>
                            <AllowAccessRoute
                                path="/quote/:uuid"
                                component={Quote}
                                reduxState={"quote"}
                            />
                            <Route
                                exact
                                path="/admin/quote/:uuid"
                                component={requiredAuth(AdminQuote)}
                            />

                            <Route
                                exact
                                path="/admin/quote/:uuid/details"
                                component={requiredAuth(AdminQuoteDetails)}
                            />

                            {/* Api Page */}
                            <Route path="/api/lead/:product/:uuid" component={ApiLead}/>

                            <Route path={"/thank-you"} component={PageThankYou}/>

                            <Route path={"/unsubscribe/:contact"} component={Unsubscribe}/>
                            <Route component={Default}/>
                        </Switch>
                    </div>
                </Suspense>
                <Footer/>
            </div>
        );
    }
}

export default withRouter(App);
