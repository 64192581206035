import React, {useEffect, useState} from 'react'
import {Formik, Form} from 'formik'
import _ from 'lodash'
import moment from 'moment'
import {Alert, Button, Col, Container, Row} from 'reactstrap'
import {FormikDebug} from '../widgets/fields/formik/formik.debug'
import {FieldSet, FieldSetRadioGroup} from '../widgets/fields/input.field'
import ModalLoader from '../widgets/modal.loader'
import config from '../../config'
import {useHistory} from 'react-router-dom'
import LoaderThankYou from '../step/loader.thank.you'

const IdScoreForm = (props) => {
  const {
    submit,
    previousDetails,
    isLoading,
    errors,
    lead_uuid,
    state,
    product,
    idScoreAttempts,
    get,
    newLeadUUID,
    reset,
    userEmail
  } = props
  const history = useHistory()
  const [thanks, setThankYou] = useState(false)

  useEffect(() => {
    if (lead_uuid) {
      get({lead_uuid: lead_uuid})
    }

    return () => reset()
  }, [lead_uuid, get, reset])

  useEffect(() => {
    if (idScoreAttempts >= 2 && !thanks) {
      setThankYou(true)
    }

    if (state === config.states.STATE_LEAD_READY_FOR_QUOTE) {
      return history.push(`${userEmail ? '/admin' : ''}/step/${product.replace(/_/g, '-')}/${newLeadUUID}`)
    }
  }, [state, idScoreAttempts, thanks, history, newLeadUUID, product, userEmail])

  if (thanks) {
    return (
      <div className={'p-2'}>
        <LoaderThankYou
          {...{
            seconds: 3,
            loaderMessage: 'Processing your details, please wait...',
            reset: () => history.push(`/step/${product.replace(/_/g, '-')}`),
            leadState: '',
            product,
            lead_uuid: newLeadUUID || lead_uuid
          }}
        />
      </div>
    )
  }

  const _errors = !_.isObject(errors) ? {} : errors

  const {address, date_of_birth, uk_since, is_in_uk_since_birth, ...rest} =
    previousDetails
  const initialValues = {
    address: {
      postcode: '',
      line1: '',
      line2: '',
      line3: '',
      line4: '',
      city: '',
      country: 'united kingdom',
      ...address
    },
    first_name: '',
    last_name: '',
    is_in_uk_since_birth:
      typeof is_in_uk_since_birth === 'boolean'
        ? is_in_uk_since_birth.toString()
        : '',
    uk_since: uk_since
      ? moment(uk_since, 'DD.MM.YYYY').format('YYYY-MM-DD')
      : '',
    date_of_birth: date_of_birth
      ? moment(date_of_birth, 'DD.MM.YYYY').format('YYYY-MM-DD')
      : '',
    ...rest
  }

  return (
    <Container>
      <Row>
        <Col>
          <Alert color={'info'} className={'mt-2'}>
            We could not verify your identity. Please review your details below
            and submit them again.
          </Alert>
        </Col>
      </Row>
      {isLoading && <ModalLoader title={'Loading data, please wait...'} />}
      <Row>
        <Col>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={(values) => {
              const is_in_uk_since_birth = !_.isEmpty(
                values.is_in_uk_since_birth
              )
                ? values.is_in_uk_since_birth === 'true'
                : ''
              const date_of_birth = !_.isEmpty(values.date_of_birth)
                ? moment(values.date_of_birth).format('DD.MM.YYYY')
                : ''
              const uk_since = !_.isEmpty(values.uk_since)
                ? moment(values.uk_since).format('DD.MM.YYYY')
                : ''
              submit({
                ...values,
                date_of_birth,
                uk_since,
                is_in_uk_since_birth,
                lead_uuid
              })
            }}
          >
            {({values}) => {
              return (
                <Form className={'m-2'}>
                  <FormikDebug debug={false} />
                  <Container>
                    <FieldSet name={'address.country'} type='hidden' />
                    <Row>
                      <Col>
                        <h3 className={'text-center'}>New Details:</h3>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs='12' sm='6'>
                        <FieldSet
                          name={'first_name'}
                          label={'First Name'}
                          error={_errors}
                        />
                      </Col>
                      <Col xs='12' sm='6'>
                        <FieldSet
                          name={'last_name'}
                          label={'Last Name'}
                          error={_errors}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs='12' sm='6'>
                        <FieldSet
                          name={'date_of_birth'}
                          label={'Date of Birth'}
                          type={'date'}
                          error={_errors}
                        />
                      </Col>
                      <Col xs='12' sm='6'>
                        <FieldSetRadioGroup
                          {...{
                            error: _errors,
                            label: 'Is in Uk since birth?',
                            name: 'is_in_uk_since_birth',
                            options: [
                              {key: 'false', value: 'NO'},
                              {key: 'true', value: 'YES'}
                            ]
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs='12' sm='6'>
                        {values.is_in_uk_since_birth === 'false' && (
                          <FieldSet
                            name={'uk_since'}
                            label={'Uk since'}
                            type={'date'}
                            error={_errors}
                          />
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs='12' sm='6'>
                        <FieldSet
                          name={'address.postcode'}
                          label={'Postcode'}
                          error={_errors}
                        />
                      </Col>
                      <Col xs='12' sm='6'>
                        <FieldSet
                          name={'address.line1'}
                          label={'Line 1'}
                          error={_errors}
                        />
                      </Col>
                      <Col xs='12' sm='6'>
                        <FieldSet
                          name={'address.line2'}
                          label={'Line 2'}
                          error={_errors}
                        />
                      </Col>
                      <Col xs='12' sm='6'>
                        <FieldSet
                          name={'address.line3'}
                          label={'Line 3'}
                          error={_errors}
                        />
                      </Col>
                      <Col xs='12' sm='6'>
                        <FieldSet
                          name={'address.line4'}
                          label={'Line 4'}
                          error={_errors}
                        />
                      </Col>
                      <Col xs='12' sm='6'>
                        <FieldSet
                          name={'address.city'}
                          label={'City'}
                          error={_errors}
                        />
                      </Col>
                      <Col xs='12' sm='6'>
                        <FieldSet
                          name={'address.country'}
                          label={'Country'}
                          error={_errors}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className={'text-center'}>
                        <Button
                          color={'success p-2'}
                          size={'lg'}
                          disabled={_.isEmpty(previousDetails)}
                        >
                          Submit
                        </Button>
                        {!_.isObject(errors) && !_.isEmpty(errors) && (
                          <Alert color={'danger'} className={'mt-2'}>
                            {errors}
                          </Alert>
                        )}
                      </Col>
                    </Row>
                  </Container>
                </Form>
              )
            }}
          </Formik>
        </Col>
      </Row>
    </Container>
  )
}
export default IdScoreForm
