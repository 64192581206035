import React from 'react';
import {FormFeedback, FormGroup, Input, InputGroup, InputGroupText, Label} from "reactstrap";
import _ from "lodash";
import PropTypes from "prop-types";

const TextInput = ({
                       label,
                       field,
                       error,
                       name,
                       handleOnChange,
                       handleOnBlur,
                       innerRef,
                       type,
                       placeholder,
                       autocomplete,
                       readonly,
                       children,
                       step,
                       prepend = null
                   }) => {
    return (
        <FormGroup>
            {
                label && <Label htmlFor={name}
                                className={` ${!_.isEmpty(error) ? 'is-invalid' : ''} `}>{label}</Label>
            }
            <InputGroup>
                {prepend ? <InputGroupText>{prepend}</InputGroupText> : <></>}
                <Input
                    {...{
                        ...field && field,
                        ...handleOnChange && {onChange: handleOnChange},
                        ...handleOnBlur && {onBlur: handleOnBlur},
                        id: name,
                        innerRef,
                        type,
                        placeholder,
                        name,
                        invalid: !_.isEmpty(error),
                        autoComplete: autocomplete,
                        readOnly: readonly,
                        step
                    }}
                >
                    {children}
                </Input>
                <FormFeedback className={'mt-2'} valid={_.isEmpty(error)}>{error}</FormFeedback>
            </InputGroup>
        </FormGroup>
    );
};
TextInput.propTypes = {
    label: PropTypes.string,
    field: PropTypes.object,
    error: PropTypes.any,
    name: PropTypes.string,
    handleOnChange: PropTypes.func,
    handleOnBlur: PropTypes.func,
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({current: PropTypes.any})
    ]),
    type: PropTypes.string,
    placeholder: PropTypes.string,
    autocomplete: PropTypes.string,
    readonly: PropTypes.bool,
    children: PropTypes.node,
    value: PropTypes.any
};


export default TextInput;
