import React, {Component} from 'react';
import {getQuestion} from "../redux/actions/step.actions";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import ModalLoader from "../components/widgets/modal.loader";

class ApiLead extends Component {
    state = {
        redirect: false
    };

    componentDidMount() {
        const {url_uuid, product, getQuestion} = this.props;
        getQuestion(product, {uuid: url_uuid});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {product} = this.props;
        if (prevProps.state[product] !== this.props.state[product]) {
            this.setState({redirect: true});
        }
    }

    render() {
        const {product} = this.props;
        const {redirect} = this.state;
        if (redirect) {
            return <Redirect to={`/${product}-step`}/>
        }
        return <ModalLoader title={'Getting your information, please wait...'}/>

    }
}

const mapStateToProps = (state, ownProps) => {
    const {uuid: url_uuid, product} = ownProps.match.params;
    return {state, url_uuid, product};
};

const mapDispatchToProps = dispatch => {
    return {
        getQuestion: (product, id) => dispatch(getQuestion(product, id)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(ApiLead);
