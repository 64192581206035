import {createAction} from 'redux-actions';
import {apiGet, apiPost} from "./api";

const actions = {
    schema: createAction('GET_SCHEMA'),
    schema_succeeded: createAction('SCHEMA_SUCCEEDED'),
    schema_failed: createAction('SCHEMA_FAILED'),
    schema_reset: createAction('SCHEMA_RESET')
};
export default actions;

export const getSchema = data => apiGet('van/schema', data, actions.schema, actions.schema_succeeded, actions.schema_failed);
export const updateSchema = data => apiPost('van/schema/update', data, actions.schema, actions.schema_succeeded, actions.schema_failed);
export const saveSchema = data => apiPost('van/schema', data, actions.schema, actions.schema_succeeded, actions.schema_failed);