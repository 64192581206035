import React from 'react';
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";
import _ from "lodash";
import PropTypes from "prop-types";

const Select = ({label, name, error, field, handleOnBlur, handleOnChange, options = [], autoComplete, innerRef, placeholder, optGroup = false}) => {
    const mapOptions = (opts) => opts.map((opt, i) => <option key={i} value={opt.key}>{opt.value}</option>);
    const _options = optGroup ? options.map(opt => <optgroup className={'text-capitalize'} label={opt.label}
                                                             key={opt.label}>{mapOptions(opt.options)}</optgroup>) : mapOptions(options);

    return (
        <FormGroup>
            {
                label && <Label htmlFor={name} className={` ${!_.isEmpty(error) ? 'is-invalid' : ''} `}>{label}</Label>
            }
            <Input
                {...{
                    ...field && field,
                    ...handleOnBlur && {onBlur: handleOnBlur},
                    id: name,
                    autoFocus: true,
                    innerRef,
                    placeholder,
                    type: 'select',
                    invalid: !_.isEmpty(error),
                    autoComplete,
                }}
                onChange={e => {
                    handleOnChange(e.target.value);
                    handleOnBlur && setTimeout(() => {
                        handleOnBlur();
                    }, 10);
                }}
            >
                <option value={''} hidden>Select an option...</option>
                {
                    _options
                }

            </Input>
            {error && (<FormFeedback valid={_.isEmpty(error)}>{error}</FormFeedback>)}
        </FormGroup>
    );
};

Select.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string.required, value: PropTypes.string.required,
    })),
    label: PropTypes.string,
    field: PropTypes.object,
    error: PropTypes.any,
    name: PropTypes.string,
    handleOnChange: PropTypes.func,
    handleOnBlur: PropTypes.func,
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({current: PropTypes.any})
    ]),
    type: PropTypes.string,
    placeholder: PropTypes.string,
    autoComplete: PropTypes.string,
    readonly: PropTypes.bool,
    optGroup: PropTypes.bool
};

export default Select;