import {createAction} from 'redux-actions';
import {apiPost, apiGet} from "./api";

const actions = {
    sms_fetch: createAction('SMS_FETCH'),
    sms_succeeded: createAction('SMS_SUCCEEDED'),
    sms_failed: createAction('SMS_FAILED'),


    link_fetch: createAction('LINK_FETCH'),
    link_succeeded: createAction('LINK_SUCCEEDED'),
    link_failed: createAction('LINK_FAILED'),
};

export default actions;

export const send = (data) => apiPost('api/sms', data, actions.sms_fetch, actions.sms_succeeded, actions.sms_failed);
export const getLink = (data) => apiGet('api/sms/link', data, actions.link_fetch, actions.link_succeeded, actions.link_failed,);
