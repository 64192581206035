export const initialValues = {
    driver: [
        {
            relationship: '',
            title: '',
            first_name: '',
            last_name: '',
            gender: '',
            telephone: '',
            email: '',
            date_of_birth: '',
            marital_status: '',
            home_owner: false,
            address:
                {
                    street: '',
                    city: '',
                    country: '',
                    county: '',
                    postcode: '',
                },
            licence:
                {
                    type: '',
                    country: '',
                    date: '',
                    number: '',
                },
            occupation: [
                {
                    part_time: false,
                    type: '',
                    business: '',
                    status: ''
                }
            ],
            has_conviction: false,
            conviction: [],
            has_incident: false,
            incident: [],
            is_in_uk_since_birth: false,
            uk_since: '',
            driving_other_vehicle: false,
            driving_status: '',
            commuting: false,
            business_use: false,
            business_miles: 1,
            personal_use: false,
            personal_miles: '',
            has_criminal_conviction: false,
            criminal_conviction: [],
            has_insurance_refused: false,
            insurance_refused: '',
            disabled: false,
            other_personal_vehicle_insured: false,
            other_business_vehicle_insured: false,
        }
    ],
    vehicle: [
        {
            cover: {
                type: '',
                date: '',
            },
            make: '',
            model: '',
            year: '',
            abi: '',
            vrm: '',
            ncb: {
                protected: false,
                years: 0,
                country: '',
                type: '',
            },
            owns_vehicle: false,
            purchase_date: '',
            value: '',
            overnight_location: '',
            overnight_location_postcode: '',
            owner: '',
            keeper: '',
            seats: 1,
            further_driving_experience: '',
            budget: '',
            payment_type: '',
            voluntary_excess: '',

            is_imported: false,
            is_left_hand_drive: false,

            has_modification: false,
            modification: [],

            carried_hazardous: false,
            carries_signage: false,
            body_type: '',
            weight: '',
            use: '',

        }
    ]
};