import React from "react";
import moment from "moment";
import {FormFeedback, Input, FormGroup, Label} from "reactstrap";
import PropTypes from "prop-types";
import _ from "lodash";

class DropDownDatePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startYear: props.startYear || moment()
                .subtract(120, "years")
                .format("YYYY"),
            endYear: props.endYear || moment().format("YYYY"),
            days: [],
            day: "",
            month: "",
            year: ""
        };
        this.renderParts = {
            day: this.renderDay,
            month: this.renderMonth,
            year: this.renderYear,
        };
    }

    mapDate = date => {
        if (!date) return {day: "", month: "", year: ""};
        const [day, month, year] = moment(date, "DD-MM-YYYY").format("DD-MM-YYYY").split("-");

        return {
            day: day || "",
            month: month || "",
            year: year || ""
        };
    };

    componentDidMount() {
        const {day, month, year} = this.mapDate(this.props.selectedDate);
        this.setState(
            {
                day,
                month,
                year
            },
            () => this.generateDays(year, month)
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {startYear, endYear, selectedDate} = this.props;
        const {
            startYear: prevStartYear,
            endYear: prevEndYear,
            selectedDate: prevSelectedDate
        } = prevProps;

        if (selectedDate && prevSelectedDate !== selectedDate) {

            const {day, month, year} = this.mapDate(selectedDate);
            if (day && month && year) {
                this.setState(
                    {
                        day,
                        month,
                        year
                    },
                    () => this.generateDays(year, month)
                );
            }
        }
        if (prevStartYear !== startYear) {
            this.setState({
                startYear
            });
        }
        if (prevEndYear !== endYear) {
            this.setState({
                endYear
            });
        }
    }

    generateYears = () => {
        const {startYear, endYear} = this.state;
        const years = [];

        for (let i = startYear; i <= endYear; i++) {
            years.push({value: i, year: i});
        }
        return years;
    };

    str_pad = n => String("00" + n).slice(-2);

    generateMonths = () => {
        let months = moment.monthsShort();

        months = months.map((m, i) => {
            return {value: this.str_pad(i + 1), month: m};
        });
        return months;
    };

    handleDateChange = () => {
        const {onDateChange} = this.props;
        if (typeof onDateChange === "function" && onDateChange) {
            const {day, month, year} = this.state;

            if (day !== "" && month !== "" && year !== "") {
                onDateChange(`${day}.${month}.${year}`);
            } else {
                onDateChange("Invalid Date");
            }
        }
    };

    generateDays = (year, month) => {
        const today = moment(),
            _days = moment(
                `${year || today.format("YYYY")}-${month || today.format("MM")}`,
                "YYYY-MM"
            ).daysInMonth(),
            days = [];

        for (let i = 1; i <= _days; i++) {
            days.push({value: this.str_pad(i), day: i});
        }
        this.setState({days}, () => this.handleDateChange());
    };

    dateIsValid = (day, month, year) => moment(`${day}.${month}.${year}`, "DD.MM.YYYY").isValid();

    handleUpdateDate = (newDate) => {
        this.setState(prevState => {
            return {
                ...prevState,
                ...newDate,
            }
        }, () => this.generateDays(this.state.year, this.state.month))
    };

    renderMonth = () => {
        const {state: {day, month, year}, props: {styles, displayMonthName, error}, handleUpdateDate, generateMonths, dateIsValid} = this;
        const err = error || null;
        const months = generateMonths();
        return (
            <Input
                type="select"
                name="month"
                id="month"
                value={month}
                style={styles && styles.month ? styles.month : {}}
                invalid={!_.isEmpty(err)}
                onChange={e => handleUpdateDate({month: e.target.value, day: dateIsValid(day, e.target.value, year) ? day : ''})}
            >
                <option value="" hidden>
                    month
                </option>
                {months.map(m => (
                    <option key={m.value} value={m.value}>
                        {displayMonthName ? m.month : m.value}
                    </option>
                ))}
            </Input>
        );
    };

    renderYear = () => {
        const {state: {day, month, year}, props: {styles, innerRef, error}, handleUpdateDate, generateYears, dateIsValid} = this;
        const err = error || null;
        const years = generateYears();
        return (
            <Input
                type="select"
                name="year"
                id="year"
                value={year}
                style={styles && styles.year ? styles.year : {}}
                onChange={e => handleUpdateDate({year: e.target.value, day: dateIsValid(day, month, e.target.value) ? day : ''})}
                autoFocus={true}
                invalid={!_.isEmpty(err)}
                innerRef={innerRef}
            >
                >
                <option value="" hidden>
                    year
                </option>
                {years.map(y => (
                    <option key={y.value} value={y.value}>
                        {y.value}
                    </option>
                ))}
            </Input>
        );
    };

    renderDay = () => {
        const {state: {days, day}, props: {styles, error}, handleDateChange} = this;
        const err = error || null;

        return (
            <Input
                type="select"
                name="day"
                id="day"
                value={day}
                style={styles && styles.day ? styles.day : {}}
                invalid={!_.isEmpty(err)}
                onChange={e =>
                    this.setState({day: e.target.value}, () => handleDateChange())
                }
            >
                <option value="" hidden>
                    day
                </option>
                {days.map(y => (
                    <option key={y.value} value={y.value}>
                        {y.value}
                    </option>
                ))}
            </Input>
        );
    };

    render() {
        const {props: {error, name, classes, order, label}, renderParts} = this;
        const err = error || null;

        return (
            <FormGroup
                className={`drop-down-date-picker-wrapper m-0 p-0 ${
                    err ? "is-invalid" : ""
                } ${classes && classes.wrapper ? classes.wrapper : ""}`}
            >
                {
                    label && <div className={"col-12 m-0 p-0"}><Label htmlFor={name} className={` ${!_.isEmpty(err) ? 'is-invalid' : ''} `}>{label}</Label></div>
                }
                {order.map(part => (
                    <div
                        className={`drop-down-date-picker m-md-0 mx-md-1 p-0 my-1 drop-down-date-picker ${
                            classes && classes[part] ? classes[part] : ""
                        }`}
                        key={part}
                    >
                        {renderParts[part]()}
                    </div>
                ))}
                {err && (
                    <FormFeedback
                        className="m-1 p-1 "
                        style={{display: err ? "block" : "none"}}
                        invalid={err}
                    >
                        {err}
                    </FormFeedback>
                )}
            </FormGroup>
        );
    }
}

DropDownDatePicker.propTypes = {
    label: PropTypes.string,
    startYear: PropTypes.string,
    endYear: PropTypes.string,
    displayMonthName: PropTypes.bool,
    name: PropTypes.string,
    error: PropTypes.any,
    selectedDate: PropTypes.string,
    order: PropTypes.arrayOf(PropTypes.string),
    classes: PropTypes.shape({
        wrapper: PropTypes.string,
        day: PropTypes.string,
        month: PropTypes.string,
        year: PropTypes.string
    }),
    styles: PropTypes.shape({
        day: PropTypes.object,
        month: PropTypes.object,
        year: PropTypes.object
    }),
    onDateChange: PropTypes.func,
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({current: PropTypes.any})
    ])
};
DropDownDatePicker.defaultProps = {
    order: ["year", "month", "day"],
    displayMonthName: false,
};

export default DropDownDatePicker;
