const PRODUCT_FASTFOOD = 'fastfood'
const PRODUCT_VAN = 'van'
const PRODUCT_COURIER_VAN = 'courier-van'
const PRODUCT_DRIVING_SCHOOL = 'driving-school'

export const products = {
    PRODUCT_FASTFOOD,
    PRODUCT_VAN,
    PRODUCT_COURIER_VAN,
    PRODUCT_DRIVING_SCHOOL
}

export const mapProduct = (product) => {
    const titles = {
        [products.PRODUCT_DRIVING_SCHOOL]: 'Driving School',
        [products.PRODUCT_FASTFOOD]: 'Fast Food Delivery',
        [products.PRODUCT_VAN]: 'Van',
        [products.PRODUCT_COURIER_VAN]: 'Courier Van',

    }
    return titles[product] ? titles[product] : ''
}
