import React from 'react';
import {Container, Row} from 'reactstrap';
import img_irev_logo from '../images/logo-irev.svg';

export default class Header extends React.Component {

  render() {
    return (
        <header>
            <Container>
                <Row className="d-flex justify-content-between align-items-sm-center align-items-lg-start">
                    <div className="col-sm-12">
                        <img className="img-fluid bigger-xxs" src={img_irev_logo} alt="insurance revolution"/>
                    </div>
                </Row>
            </Container>
        </header>
    );
  }
}
