import {handleActions} from 'redux-actions';
import actions from '../actions/bank.holydays';
import {API_END, API_START} from "../constants/api.constants";
import _ from "lodash";

export default handleActions({
    [actions.bank_holidays]: (state, action) => {
        switch (action.payload) {
            case API_START:
                return {...state, isLoading: true, errors: {}, days: {}};
            case API_END:
                return {...state, isLoading: false};
            default:
                return state;
        }
    },
    [actions.bank_holidays_succeeded]: (state, action) => {
        return {
            ...state,
            days: action.payload,
            errors: {}
        }
    },
    [actions.bank_holidays_failed]: (state, action) => {
        const errors = _.get(action, 'payload.data.errors');
        return {
            ...state,
            errors: !_.isEmpty(errors) ? errors : _.get(action, 'payload.data.message') || 'Please try later'
        }
    }

}, getDefaults());

export function getDefaults() {
    return {
        isLoading: false,
        days: {},
        errors: {},
    }
}
