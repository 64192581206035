import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import {FieldSet} from '../widgets/fields/input.field';
import {getIrosUsersURL} from '../../utils/urls';
import _ from 'lodash';

class FormLogin extends Component {

  render() {
    const { handleSubmit, login: { errors } } = this.props;

    return (
      <div>
        <h1 className="text-center">
          Login
          </h1>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-6 offset-sm-3">
              <FieldSet name='email' type="text" label="E-mail Address" error={errors} />
              <FieldSet name='password' type="password" label="Password" error={errors} />

              <div className="text-center">
                <button type="submit" className="btn btn-primary btn-lg">Login</button>
              </div>

              <hr />
              <p className="text-center">
                <a href={`${getIrosUsersURL()}/#/request-password-reset`} className="btn btn-link btn-sm">
                  I've forgotten my password
                </a>
              </p>

            </div>
          </div>
        </form>
      </div>
    );
  }

  componentWillUnmount() {
    this.props.onNavigate();
  }

  static propTypes = {
    onLogin: PropTypes.func.isRequired,
  };
}

export default withFormik({
  handleSubmit: (values, _this) => _this.props.onLogin(_.pick(values, ['email', 'password'])),
})(FormLogin);

