import React from 'react';
import PropTypes from 'prop-types';
import config from "../config";
import {Button} from "reactstrap";

const PageNoQuote = ({text, telephone, newQuote, product, uuid}) => {
    const info = `Thank you for providing your information to obtain a ${product} insurance quote.`
    return (
        <div className='container thank-you p-1'>
            <div id="complete" hidden className='visiblity: hidden;'></div>
            <h1 className='text-capitalize'>thank you</h1>
            <p>
                {info}{' '}<strong>We can't provide you a quote.</strong>
            </p>
            <p>
                <strong>{text}</strong>
            </p>
            <p>
                Please contact us if you need to change your information.
            </p>
            <p>
                Our phone number to call us <strong><a href={`tel:${telephone}`}>{telephone}</a></strong>
            </p>
            <hr/>
            <h2>Our Opening Hours <span className='text-muted'>(Excluding Bank Holidays)</span></h2>
            <p>
                {config.timeTable.week.text}
            </p>
            <p>
                {config.timeTable.saturday.text}
            </p>
            <hr/>
            <p>
                <Button color='danger' onClick={() => newQuote(uuid)}>New Quote</Button>
            </p>
        </div>
    );
};
//todo add link
PageNoQuote.propTypes = {
    text: PropTypes.string.isRequired,
    telephone: PropTypes.string.isRequired,
    newQuote: PropTypes.func.isRequired,
    product: PropTypes.string.isRequired,
    uuid: PropTypes.string
};
export default PageNoQuote;
