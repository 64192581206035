import {handleActions} from 'redux-actions';
import actions from '../actions/buy';
import {API_END, API_START} from '../constants/api.constants';

import _ from 'lodash';


const updateUuidObject = (_state, action, new_data, _uuid = null) => {
    const state = {..._state};
    const uuid = _.get(action, 'payload.data.uuid', '') || _uuid;

    if (!uuid) return state;
    if (!state[uuid]) state[uuid] = {};
    state[uuid] = {...state[uuid], ...new_data};

    return state;
};

const getNextPage = path => {
    if (path === 'risk') return 'agreement';
    if (path === 'agreement') return 'payment'
    return path;
}

const getPrevPage = path => {
    if (path === 'risk') return '';
    if (path === 'agreement') return ''
    if (path === 'payment') return 'agreement';
    return path;
}
const getPagePath = (redirect, path) => {
    if (redirect === 'next') {
        return getNextPage(path)
    }
    return getPrevPage(path);
}

export default handleActions({
    [actions.buy_get_quote]: (state, action) => {
        const {uuid} = action;
        switch (action.payload) {
            case API_START:
                return updateUuidObject(state, action, {...getDefaults(), isLoading: true, errors: {}}, uuid)
            case API_END:
                return updateUuidObject(state, action, {isLoading: false}, uuid)
            default:
                return state;
        }
    },
    [actions.buy_risk_succeeded]: (state, action) => {
        const {uuid} = action;
        const path = _.get(action, 'payload.path', '');
        const redirect = _.get(action, 'payload.redirect', false);
        const redirect_to = _.get(action, 'payload.redirect_to', '');
        const redirect_path = redirect ? `/buy/${uuid}/${getPagePath(redirect, path)}` : '';

        return updateUuidObject(state, action, {...action.payload, redirect_path, redirect_to}, uuid)
    },
    [actions.buy_risk_failed]: (state, action) => {
        const {uuid} = action;
        const _errors = _.get(action, 'payload.data.errors');
        const errors = !_.isEmpty(_errors) ? _errors : _.get(action, 'payload.data.message') || 'Please try later';
        return updateUuidObject(state, action, {errors}, uuid)
    },
}, getDefaults());

export function getDefaults() {
    return {
    };
}
