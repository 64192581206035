import {createAction} from "redux-actions";
import {apiGet, apiPost} from "./api";

const actions = {
    get_quote_list_succeeded: createAction("ADMIN_GET_QUOTE_LIST_SUCCEEDED"),

    get_quotes: createAction("ADMIN_GET_QUOTE"),
    quote_succeeded: createAction("ADMIN_QUOTE_SUCCEEDED"),
    quote_failed: createAction("ADMIN_QUOTE_FAILED"),

    send_discount: createAction("ADMIN_SEND_DISCOUNT"),
    reset_discount: createAction("ADMIN_RESET_DISCOUNT"),
    discount_failed: createAction("ADMIN_DISCOUNT_FAILED"),

    fetching_update_state: createAction('ADMIN_FETCHING_UPDATE_STATE_SUCCEEDED'),
    update_state_succeeded: createAction('ADMIN_UPDATE_STATE_SUCCEEDED'),
    update_state_failed: createAction('ADMIN_UPDATE_STATE_FAILED'),

    requote: createAction("ADMIN_REQUOTE"),
    requote_succeeded: createAction("ADMIN_REQUOTE_SUCCEEDED"),
    requote_failed: createAction("ADMIN_REQUOTE_FAILED"),
};

export default actions;

export const getQuoteList = (data) =>
    apiGet(
        "admin/quotes",
        data,
        actions.get_quotes,
        actions.get_quote_list_succeeded,
        actions.quote_failed
    );

export const getQuotes = (id) =>
    apiGet(
        "admin/quotes/quote",
        id,
        actions.get_quotes,
        actions.quote_succeeded,
        actions.quote_failed
    );

export const submitDiscount = (data) =>
    apiPost(
        "admin/quotes/discount",
        data,
        actions.send_discount,
        actions.quote_succeeded,
        actions.discount_failed
    );

export const setQuoteState = data => apiPost('admin/quotes/update-state', data, actions.fetching_update_state, actions.update_state_succeeded, actions.update_state_failed)
export const resetDiscounts = () => ({type: actions.reset_discount.toString()})

export const reQuote = (uuid = '', product = '', step = '') => apiPost(
    `admin/quotes/re-quote/${uuid}`,
    {step},
    actions.requote,
    res => actions.requote_succeeded({...res, product}),
    actions.requote_failed
);