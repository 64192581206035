import { handleActions } from "redux-actions";
import actions from "../actions/quote";
import { API_END, API_START } from "../constants/api.constants";

import _ from "lodash";

export default handleActions(
    {
        [actions.get_quotes]: (state, action) => {
            switch (action.payload) {
                case API_START:
                    return {
                        ...state,
                        isLoading: true,
                        errors: {},
                    };
                case API_END:
                    return { ...state, isLoading: false };
                default:
                    return state;
            }
        },
        [actions.quote_succeeded]: (state, action) => {
            const quote = _.get(state, action.payload.uuid, {});
            const discounts = _.get(action, "payload.discounts", {});

            return {
                ...state,
                [action.payload.uuid]: {
                    ...quote,
                    ...action.payload,
                },
                discounts: {
                    ...state.discounts,
                    ...discounts
                },
            };
        },
        [actions.quote_failed]: (state, action) => {
            const errors = _.get(action, "payload.data.errors");
            return {
                ...state,
                errors: !_.isEmpty(errors)
                    ? errors
                    : _.get(action, "payload.data.message") || "Please try later",
            };
        },
        [actions.generate_quotes_succeeded]: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        },
        [actions.generate_quotes_failed]: (state, action) => {
            const errors = _.get(action, "payload.data.errors");
            return {
                ...state,
                errors: !_.isEmpty(errors)
                    ? errors
                    : _.get(action, "payload.data.message") || "Please try later",
            };
        },
        [actions.requote]: (state, action) => {
            switch (action.payload) {
                case API_START:
                    return {
                        ...state,
                        isLoading: true,
                        errors: {},
                    };
                case API_END:
                    return { ...state, isLoading: false };
                default:
                    return state;
            }
        },
        [actions.requote_succeeded]: (state, action) => {
            const uuid = _.get(action, 'payload.leadUUID', '');
            const {product} = action.payload
            const redirect_path = `/step/${product}/${uuid}`;
            return {
                ...state,
                ...action.payload,
                redirect_path
            };
        },
        [actions.requote_failed]: (state, action) => {
            const errors = _.get(action, "payload.data.errors");
            return {
                ...state,
                errors: !_.isEmpty(errors)
                    ? errors
                    : _.get(action, "payload.data.message") || "Please try later",
            };
        },
    },
    getDefaults()
);

export function getDefaults() {
    return {
        isLoading: false,
        errors: {},
    };
}
