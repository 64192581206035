import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config';
import {Button} from 'reactstrap';

const FastFoodThankYou = ({newQuote, uuid, product, telephone}) => {
    return (
        <div className='container thank-you p-1'>
            <h1 className='text-capitalize'>thank you</h1>
            <div gp="true" id="complete" hidden className='visiblity: hidden;'></div>
            <p>
                {`Thank you for providing your information to obtain a ${product} delivery insurance quote. Due to the
                nature of the risk the premium has to be signed off from a
                underwriter. This normally takes around 10 minutes but please provide upto an hour in working hours. Out
                of hours you might not get your price back until we open the next day.`}
            </p>
            <p>
                Our phone number to call us <strong><a href={`tel:${telephone}`}>{telephone}</a></strong>
                When the quote is ready, you will receive an email and a text message from us. Please keep an eye on
                your inbox and your phone.
            </p>
            <hr/>
            <h2>Our Opening Hours <span className='text-muted'>(Excluding Bank Holidays)</span></h2>
            <p>
                {config.timeTable.week.text}
            </p>
            <p>
                {config.timeTable.saturday.text}
            </p>
            <hr/>
            <p>
                If you want to change your details or get a new quote, please click on the button below
            </p>
            <p>
                <Button color='danger' onClick={() => newQuote(uuid)}>New Quote</Button>
            </p>
        </div>
    );
};
FastFoodThankYou.propTypes = {
    newQuote: PropTypes.func.isRequired,
    uuid: PropTypes.string,
    telephone: PropTypes.string,
    product: PropTypes.string
};
export default FastFoodThankYou;
