import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import AppError from './app.error';
import * as serviceWorker from './serviceWorker';
//our styles
import './index.css';
//redux
import {Provider} from 'react-redux';
import {store, persistor, clearStorage} from './redux/configure.store';
//redux persist
import {PersistGate} from 'redux-persist/integration/react';

//router
import {HashRouter as Router} from 'react-router-dom';

const reset = () => {
  persistor
      .purge()
      .then(() => persistor.flush())
      .then(() => {
        setTimeout(() => window.location.reload(), 100);
      });
};

ReactDOM.render(
    <AppError reset={reset}>
      <Provider store={store}>
        <Router>
          <PersistGate loading={null} onBeforeLift={clearStorage} persistor={persistor}>
            <App/>
          </PersistGate>
        </Router>
      </Provider>
    </AppError>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
