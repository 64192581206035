import {createAction} from "redux-actions";
import {apiGet, apiPost} from "./api";

const actions = {
    get_quotes: createAction("GET_QUOTE"),
    quote_succeeded: createAction("QUOTE_SUCCEEDED"),
    quote_failed: createAction("QUOTE_FAILED"),

    generate_quotes_succeeded: createAction("GENERATE_QUOTES_SUCCEEDED"),
    generate_quotes_failed: createAction("GENERATE_QUOTES_FAILED"),

    requote: createAction("REQUOTE"),
    requote_succeeded: createAction("REQUOTE_SUCCEEDED"),
    requote_failed: createAction("REQUOTE_FAILED"),
};

export default actions;

export const getQuotes = (id) =>
    apiGet(
        "quote",
        id,
        actions.get_quotes,
        actions.quote_succeeded,
        actions.quote_failed
    );
export const generateQuotes = (product, lead_uuid) =>
    apiPost(
        product,
        {uuid: lead_uuid},
        actions.get_quotes,
        actions.generate_quotes_succeeded,
        actions.generate_quotes_failed
    );
export const reQuote = (uuid, step, product = '') =>
    apiPost(
        `quote/re-quote/${uuid}`,
        {step},
        actions.requote,
        (res) => actions.requote_succeeded({...res, product}),
        actions.requote_failed
    );
