import axios from 'axios';
import {API, API_END, API_ERROR, API_START, API_SUCCESS} from '../constants/api.constants';
import {apiError, apiStart, apiEnd, apiSuccess} from '../actions/api';
import adminActions from '../actions/admin'

const apiMiddleware = ({dispatch, getState}) => next => action => {
    if (action.type !== API) return next(action);


    const state = getState(),
        access_token = state?.admin?.login?.access_token;

    const {
        url,
        method,
        data,
        onSuccess,
        onFailure,
        headers,
    } = action.payload;
    let {
    label,
  } = action.payload;

  if (label) typeof label === 'function' ? dispatch(label(API_START)) : dispatch(apiStart(label));

    axios
        .request({
            url,
            method,
            headers: {
                'content-type': 'application/json',
                'Authorization': `JWT ${access_token}`,
                ...headers
            },
            [['GET'].includes(method) ? 'params' : 'data']: data,
        })
        .then(({data}) => {
            if (label) typeof label === 'function' ? dispatch(label(API_SUCCESS)) : dispatch(apiSuccess(label));
            dispatch(onSuccess(data));
        })
        .catch(({response}) => {
            if (label) typeof label === 'function' ? dispatch(label(API_ERROR)) : dispatch(apiError(label));

            if (response?.status === 401) {
                dispatch(adminActions.logout())
            }

            dispatch(onFailure(response));
        })
        .finally(() => {
            if (label) typeof label === 'function' ? dispatch(label(API_END)) : dispatch(apiEnd(label));
        });
};

export default apiMiddleware;
