import React from 'react';
import config from "../../config";
import NoQuote from "../page.no.quote";
import ProductThanks from "../products/product.thank.you";
import PropTypes from "prop-types";
import {mapProduct} from "../../utils/products";

const ThankYou = ({reset, lead_state, product, lead_uuid}) => {
    const productMapped = mapProduct(product),
        telephone = config.phoneNumbers[product];

    switch (lead_state) {
        case config.states.STATE_DRIVER_UNDER_21:
            return <NoQuote {...{
                text: 'There is a driver with less than 21 years old.',
                telephone,
                newQuote: reset,
                product: productMapped,
                uuid: lead_uuid
            }}/>;
        case config.states.STATE_LICENCE_UNDER_12:
            return <NoQuote {...{
                text: 'There is a driver with full licence that has less than 12 months.',
                telephone,
                newQuote: reset,
                product: productMapped,
                uuid: lead_uuid
            }}/>;
        default:
            return <ProductThanks {...{newQuote: reset, uuid: lead_uuid, product: mapProduct(product), telephone}}/>;
    }

};

ThankYou.propTypes = {
    reset: PropTypes.func,
    lead_state: PropTypes.string,
    product: PropTypes.string,
    lead_uuid: PropTypes.string,
};

export default ThankYou;
