import {createAction} from "redux-actions";
import {apiPost} from "./api";

const actions = {
    unsubscribe_processing: createAction('UNSUBSCRIBE_PROCESSING'),
    unsubscribe_succeeded: createAction('UNSUBSCRIBE_SUCCEEDED'),
    unsubscribe_failed: createAction('UNSUBSCRIBE_FAILED'),
};

export default actions;

export const unsubEmail = data => apiPost('unsubscribe/email', data, actions.unsubscribe_processing, actions.unsubscribe_succeeded, actions.unsubscribe_failed)
export const optoutTelephone = data => apiPost('unsubscribe/telephone', data, actions.unsubscribe_processing, actions.unsubscribe_succeeded, actions.unsubscribe_failed)
