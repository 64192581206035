import { handleActions } from 'redux-actions';
import actions from '../actions/user';
import { API_END, API_LOADING, API_READY, API_START } from '../constants/api.constants';
import _ from 'lodash';

export default handleActions({

    [actions.user_login]: (state, action) => {
        switch (action.payload) {
            case API_START:
                return { ...state, login: { ...state.login, errors: {}, status: API_LOADING } };
            case API_END:
                return { ...state, login: { ...state.login, status: API_READY } };
            default:
                return state;
        }
    },
    [actions.user_login_failed]: (state, action) => {
        const errors = _.get(action, 'payload.data.errors');
        return { ...state, login: { ...state.login, errors: !_.isEmpty(errors) ? errors : _.get(action, 'payload.data.message'), success_message: '' } };
    },
    [actions.user_login_succeeded]: (state, action) => {
        return { ...state, login: { ...action.payload } };
    }

}, getDefaults());

export function getDefaults() {
    return {
        login: {
            vrm: null,
            postcode: null,
            dob: null
        }
    };
}
