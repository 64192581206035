import React, { Component } from "react";
import _ from "lodash";
import {
  Button,
  NavItem,
  Navbar,
  Nav,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Jumbotron,
} from "reactstrap";

import { products, mapProduct } from "../../utils/products";

import {
  canAccess,
  isAuthorized,
  ROLES,
  SECTIONS,
} from "../../utils/admin.utils";
import * as adminUtils from "../../utils/admin.utils";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import actions, { login } from "../../redux/actions/admin";
import AdminLoginForm from "./form.login";

export default function (ComposedComponent, _props) {
  class PageAuthorised extends Component {
    state = {
      openNav: false,
    };

    canAccessStepForm = () =>
      canAccess(this.props.login, ROLES.USER, SECTIONS.SECTION_STEP_FORM);
    canAccessQuotes = () =>
      canAccess(this.props.login, ROLES.USER, SECTIONS.SECTION_QUOTES);
    canAccessGraniteSender = () =>
      canAccess(this.props.login, ROLES.USER, SECTIONS.SECTION_GRANITE_SENDER);
    canAccessLeads = () =>
      canAccess(this.props.login, ROLES.USER, SECTIONS.SECTION_STEP_FORM);

    toggleNav = () =>
      this.setState((prevState) => ({
        ...prevState,
        openNav: !prevState.openNav,
      }));

    _renderNav() {
      const {
        onLogout,
        login: { email },
      } = this.props;

      const { openNav } = this.state;
      const { toggleNav } = this;

      const _stepForms = () =>
        _.map(products, (v, k) => (
          <DropdownItem key={k}>
            <NavLink href={`/#/admin/step/${v.replace(/_/g, "-")}`}>
              {mapProduct(v)}
            </NavLink>
          </DropdownItem>
        ));

      const _quotes = () =>
        _.map(
            ["pending", "quoted", "paid", "processed", "cancelled", "failed"],
            (v, k) => (
                <DropdownItem key={k}>
                  <NavLink href={`/#/admin/quotes/${v}`}>{_.capitalize(v)}</NavLink>
                </DropdownItem>
            )
        );

      const _navItems = () => {
        return (
          <Nav className="mr-auto" navbar>
            {this.canAccessGraniteSender() && (
              <NavItem>
                <NavLink href="/#/admin/forward/granite">
                  Access Granite Sender
                </NavLink>
              </NavItem>
            )}
            {this.canAccessStepForm() && (
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Step Forms
                </DropdownToggle>
                <DropdownMenu left="true">{_stepForms()}</DropdownMenu>
              </UncontrolledDropdown>
            )}
            {this.canAccessQuotes() && (
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Quotes
                </DropdownToggle>
                <DropdownMenu left="true">
                  <DropdownItem key={"all"}>
                    <NavLink href={`/#/admin/quotes`}>All</NavLink>
                  </DropdownItem>
                  {_quotes()}
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
            {this.canAccessLeads() && (
              <NavItem>
                <NavLink href="/#/admin/leads">Leads</NavLink>
              </NavItem>
            )}
          </Nav>
        );
      };

      return (
        <Navbar color="light" light expand="md">
          <NavbarBrand href="/#/admin">Iros Marketplace</NavbarBrand>
          <NavbarToggler onClick={toggleNav} />
          <Collapse isOpen={openNav} navbar>
            {_navItems()}
            <Button outline color="secondary" onClick={onLogout}>
              Logout ({email})
            </Button>
          </Collapse>
        </Navbar>
      );
    }

    userEmail() {
      return adminUtils.getUserEmail(this.props);
    }

    _renderLogin() {
      const {
        login: { email, errors, success_message },
        onLogin,
        login,
        onNavigate,
      } = this.props;

      return (
        <Jumbotron>
          {!_.isEmpty(errors) && (
            <div className="alert alert-danger text-center">
              {_.isString(errors) ? errors : "Please fix the errors below"}
            </div>
          )}
          {success_message.length > 0 && (
            <div className="alert alert-success text-center">
              {success_message}
            </div>
          )}
          <AdminLoginForm
            {...{ onLogin, login, onNavigate }}
            initialValues={{ email }}
          />
        </Jumbotron>
      );
    }

    render() {
      if (!isAuthorized(this.props.login)) return this._renderLogin();

      return (
        <main>
          {this._renderNav()}
          <div className={"container-fluid pt-2"}>
            <ComposedComponent
              {...{
                ...this.props,
                userEmail: this.userEmail(),
                hasStepFormSection: this.canAccessStepForm(),
                hasGraniteSection: this.canAccessGraniteSender(),
              }}
            />
          </div>
        </main>
      );
    }
  }

  const mapStateToProps = (state) => {
    return { ...state.admin, ..._props };
  };

  const mapDispatchToProps = (dispatch) => {
    const admin = bindActionCreators(actions, dispatch);
    return {
      onLogin: (data) => dispatch(login(data)),
      onLogout: () => dispatch(admin.logout),
      onNavigate: () => dispatch(admin.navigate),
    };
  };
  return connect(mapStateToProps, mapDispatchToProps)(PageAuthorised);
}
