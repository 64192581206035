import actions from '../actions/step.actions';
import {
    actionGetQuestion,
    actionStepFailed,
    actionStepReset,
    actionStepSucceeded,
    getDefaults,
    stepReducer
} from "./_common.step.reducer";

const actionsHandle = {
    [actions.step_get_question]: actionGetQuestion,
    [actions.step_succeeded]: actionStepSucceeded(),
    [actions.step_failed]: actionStepFailed,
    [actions.step_reset]: actionStepReset()
};

export {getDefaults};
export default stepReducer(actionsHandle);

