import {createAction} from 'redux-actions';
import {apiGet} from './api';

const actions = {
    bank_holidays: createAction('BANK_HOLIDAYS_GET'),
    bank_holidays_succeeded: createAction('BANK_HOLIDAYS_SUCCEEDED'),
    bank_holidays_failed: createAction('BANK_HOLIDAYS_FAILED')
};
export default actions;

export const getBankHoliday = () => apiGet('bankholidays', '', actions.bank_holidays, actions.bank_holidays_succeeded, actions.bank_holidays_failed);