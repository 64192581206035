import React, {useEffect, useState} from 'react';
import ThankYou from "./thank.you";
import Loader from "../widgets/loader";

const LoaderThankYou = ({seconds, reset, lead_state, product, lead_uuid, loaderMessage}) => {
    const [timer, setTimer] = useState(seconds);
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimer(timer => timer - 1);
        }, 1000);

        if (timer <= 0) {
            clearInterval(interval);
            setRedirect(true);
        }

        return () => {
            clearInterval(interval);
        }
    }, [timer]);

    if (!redirect) return <div className={'d-flex justify-content-center align-items-center flex-column'} style={{minHeight: '50vh'}}>
        <div className={'m-2'}>
            <h3 className={'text-center'}>{loaderMessage}</h3>
        </div>
        <div className={'w-100'}>
            <Loader/>
        </div>
    </div>

    return <ThankYou {...{
        reset,
        lead_state,
        product,
        lead_uuid,
    }}/>
};

export default LoaderThankYou;
