import {apiPost} from "./api";
import {createAction} from 'redux-actions';

export const getSteps = () => {
    return [
        {key: "you", label: "You", page: 0},
        {key: "driver", label: "Drivers", page: 1},
        {key: "vehicle", label: "Vehicle", page: 2},
        {key: "finish", label: "Quote", page: 3}
    ];
};


const actions = {
    van_validate: createAction('VAN_REQUEST_VALIDATION'),
    van_send_quote: createAction('VAN_REQUEST_SEND_QUOTE'),

    van_succeeded: createAction('VAN_REQUEST_SUCCESS'),
    van_failed: createAction('VAN_REQUEST_FAILED'),

    van_reset: createAction('VAN_RESET'),

    van_add_data: createAction('VAN_ADD_DATA', data => data),
    van_remove_data: createAction('REMOVE_VAN_DATA', (index, property) => {
        return {index, property}
    }),

    set_next_step: createAction('VAN_SET_NEXT_STEP', step => step),
    go_to_next: createAction('VAN_GO_TO_NEXT', (steps, current) => {
        return {steps, current}
    }),
    go_to: createAction('VAN_GO_TO', (steps, current, to) => {
        return {steps, current, to}
    })
};
export default actions;


export const validateForm = (data, url_dest) => {
    const {driver, vehicle, uuid} = data;
    switch (url_dest) {
        case "you":
            data = {driver, uuid};
            break;
        case "driver":
            data = {driver, uuid};
            break;
        case "vehicle":
            data = {vehicle, uuid};
            break;
        default:
            break;

    }
    return apiPost(`van/${url_dest}`, data, actions.van_validate, actions.van_add_data, actions.van_failed)
};

export const sendQuote = data => apiPost('van', data, actions.van_send_quote, actions.van_succeeded, actions.van_failed);

export const addVanData = data => actions.van_add_data(data);
export const removeVanData = (index, property) => actions.van_remove_data(index, property);

export const setNextStep = step => actions.set_next_step(step);
export const goToNext = (steps, current) => actions.go_to_next(steps, current);
export const goTo = (steps, current, to) => actions.go_to(steps, current, to);


