export const BLANK_DRIVER = {
    relationship: '',
    title: '',
    first_name: '',
    last_name: '',
    gender: '',
    telephone: '',
    email: '',
    date_of_birth: '',
    marital_status: '',
    home_owner: false,
    address:
        {
            street: '',
            city: '',
            country: '',
            county: '',
            postcode: '',
        },
    licence:
        {
            type: '',
            country: '',
            date: '',
            number: '',
        },
    occupation: [
        {
            part_time: false,
            type: '',
            business: '',
            status: ''
        }
    ],
    has_conviction: false,
    conviction: [],
    has_incident: false,
    incident: [],
    is_in_uk_since_birth: false,
    uk_since: '',
    driving_other_vehicle: false,
    driving_status: '',
    commuting: false,
    business_use: false,
    business_miles: '',
    personal_use: false,
    personal_miles: '',
    has_criminal_conviction: false,
    criminal_conviction: [],
    has_insurance_refused: false,
    insurance_refused: '',
    disabled: false,
    other_personal_vehicle_insured: false,
    other_business_vehicle_insured: false,
};
export const BLANK_VEHICLE = {
    cover: {
        type: '',
        date: '',
    },
    make: '',
    model: '',
    year: '',
    abi: '',
    vrm: '',
    ncb: {
        protected: true,
        years: '',
        country: '',
        type: '',
    },
    owns_vehicle: true,
    purchase_date: '',
    value: '',
    overnight_location: '',
    overnight_location_postcode: '',
    owner: '',
    keeper: '',
    seats: '',
    further_driving_experience: '',
    budget: '',
    payment_type: '',
    voluntary_excess: '',

    is_imported: false,
    is_left_hand_drive: false,

    has_modification: true,
    modification: [],

    carried_hazardous: true,
    carries_signage: true,
    body_type: '',
    weight: '',
    use: '',
};

export const BLANK_INCIDENT = {
    date: '',
    claim: '',
    type: '',
    fault: '',
    ncb_affected: false,
    bodily_injured: false,
    policy: '',
};
export const BLANK_OCCUPATION = {
    part_time: true,
    type: '',
    business: '',
    status: '',
};


export const BLANK_CONVICTION = {
    date: '',
    points: null,
    type: '',
    details: '',
    sample: '',
    blood: 0,
    breath: 0,
    urine: 0,
    is_fined: false,
    is_disqualified: false,
    fine: 0,
    disqualification: 0,
};
export const BLANK_CRIMINAL_CONVICTION = {
    date: '',
    reason: '',
    received: ''
};


export const BLANK_DRIVER_TEST = {
    relationship: "proposer",
    title: "Mr",
    first_name: "John",
    last_name: "Doe",
    gender: "male",
    telephone: "+447852000000",
    email: "johndoe@non-existing-domain.com",
    date_of_birth: '01.01.1990',
    marital_status: 'married',
    home_owner: true,
    address:
        {
            street: 'Chorlton stree',
            city: 'Manchester',
            country: 'united kingdom',
            county: 'Man',
            postcode: 'M1',
        },
    licence:
        {
            type: 'full',
            country: 'united kingdom',
            date: '01.01.1990',
            number: '1',
        },
    occupation: [
        {
            part_time: false,
            type: 'abstractor',
            business: 'restaurant - licensed',
            status: 'employed'
        }
    ],
    has_conviction: false,
    conviction: [/*{
                date: '01.01.1990',
                points: 10,
                type: 'DG10',
                details: 'details conviction',
                sample:'blood',
                blood:1,
                breath:1,
                urine:2,
                is_fined: true,
                is_disqualified: true,
                fine:50,
                disqualification: 25,
            }*/],
    has_incident: false,
    incident: [/*{
                date: '01.01.1990',
                claim: 'accident',
                type: 'hit parked tp',
                fault: 'no blame',
                ncb_affected: true,
                bodily_injured: true,
                policy: 'company',
            }*/],
    is_in_uk_since_birth: true,
    uk_since: '01.01.1990',
    driving_other_vehicle: false,
    driving_status: 'main',
    commuting: false,
    business_use: true,
    business_miles: 1,
    personal_use: true,
    personal_miles: 0,
    has_criminal_conviction: false,
    criminal_conviction: [/*{
                date: '01.01.1990',
                reason: 'some reason',
                received: 'received'
            }*/],
    has_insurance_refused: false,
    insurance_refused: '',
    disabled: false,
    other_personal_vehicle_insured: false,
    other_business_vehicle_insured: false,
};
export const BLANK_VEHICLE_TEST = {
    cover: {
        type: 'comprehensive',
        date: '10.02.2019',
    },
    make: 'TOYOTA',
    model: 'PRIUS T SPIRIT VVT-I CVT',
    year: 2012,
    abi: '50061901',
    vrm: 'WV12BAO',
    ncb: {
        protected: true,
        years: 5,
        country: 'united kingdom',
        type: 'commercial vehicle',
    },
    owns_vehicle: true,
    purchase_date: '30.12.2018',
    value: 10000,
    overnight_location: 'locked compound',
    overnight_location_postcode: 'M13HY',
    owner: 'company',
    keeper: 'proposer',
    seats: 5,
    further_driving_experience: '',
    budget: '5000',
    payment_type: 'monthly',
    voluntary_excess: '500',

    is_imported: false,
    is_left_hand_drive: false,

    has_modification: true,
    modification: [
        'altered pedal configuration',
        'sunroof',
    ],

    carried_hazardous: true,
    carries_signage: true,
    body_type: '',
    weight: '',
    use: 'sdp excluding commuting',
};


//Limits
export const MAX_OCCUPATIONS = 2;
export const MAX_CONVICTIONS = 5;
export const MAX_INCIDENTS = 5;