import React from 'react';
import Loader from './loader';
import styled from 'styled-components';

const ModalLoader = ({title}) => {
    return (
        <ModalContainer>
            <div className='container modal-loader-container'>
                <div className='row'>
                    <div className='modal-loader col-8 mx-auto col-md-8 col-lg-8
                            text-center text-capitalize modal-content'>
                        <div className='modal-header'>
                            <h4 className='modal-title'>{title}</h4>
                        </div>
                        <div className='modal-body'>
                            <Loader/>
                        </div>
                    </div>
                </div>
            </div>
        </ModalContainer>
    );
};

const ModalContainer = styled.div`
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  right:0;
  bottom:0;
  background: rgba(0,0,0,0.3);
  display: flex;
  align-items:center;
  justify-content: center;  
  .modal-header{
    display:flex;
    justify-content: space-between;
    align-items: center;
  }
  #modal{
    background: #f3f3f3;
    border-radius: 0.5rem;
    max-height: 80vh;
    overflow-y: auto;  
  }   
`;

export default ModalLoader;
