import {createAction} from 'redux-actions';
import {apiPost, apiGet} from "./api";

const actions = {
    fetch: createAction('ID_SCORE_FETCH'),
    get_succeeded: createAction('ID_SCORE_GET_SUCCEEDED'),
    submit_succeeded: createAction('ID_SCORE_SUBMIT_SUCCEEDED'),
    failed: createAction('ID_SCORE_FAILED'),
    reset: createAction('ID_SCORE_RESET')
};

export default actions;

export const submit = (data) => apiPost('lead/id-score', data, actions.fetch, actions.submit_succeeded, actions.failed);
export const get = (data) => apiGet('lead/id-score', data, actions.fetch, actions.get_succeeded, actions.failed);
export const reset = () => actions.reset()
