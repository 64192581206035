import actions from "../actions/admin.step.actions";
import {
    actionGetQuestion,
    actionStepFailed,
    actionStepReset,
    actionStepSucceeded,
    stepReducer
} from "./_common.step.reducer";

const actionsHandle = {
    [actions.step_get_question]: actionGetQuestion,
    [actions.step_succeeded]: actionStepSucceeded('/admin'),
    [actions.step_failed]: actionStepFailed,
    [actions.step_reset]: actionStepReset('/admin')
};

export default stepReducer(actionsHandle);
