import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import _ from 'lodash';

const AllowAccessRoute = ({
                              component: Component,
                              auth,
                              ...rest
                          }) => {
    return (
        <Route
            {...rest}
            render={props =>
                auth?.allowAccess ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/thank-you"/>
                )
            }
        />
    );
}

AllowAccessRoute.propTypes = {
    auth: PropTypes.object.isRequired
}

const mapStateToProps = (state, ownProps) => {
    const _state = state[ownProps?.reduxState]

    return ({
        auth: {
            allowAccess: _.get(_state, 'errors.allowAccess', true)
        }
    });
}

export default connect(mapStateToProps)(AllowAccessRoute);
