import { createAction } from 'redux-actions';
import { apiPost } from './api';

const actions = {
    user_login: createAction('USER_LOGIN'),
    user_login_succeeded: createAction('USER_LOGIN_SUCCEEDED'),
    user_login_failed: createAction('USER_LOGIN_FAILED')
};
export default actions;

export const login = (uuid, data) => apiPost(`quote/verify/${uuid}`, data, actions.user_login, actions.user_login_succeeded, actions.user_login_failed);
