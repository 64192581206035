import {createAction} from 'redux-actions';
import {apiGet, apiPost} from './api';

const actions = {
    search: createAction('LOOKUP_SEARCH'),
    select: createAction('LOOKUP_SELECT'),
    reset_address: createAction('LOOKUP_RESET_ADDRESS'),
    search_succeeded: createAction('LOOKUP_SEARCH_SUCCEEDED'),
    select_succeeded: createAction('LOOKUP_SELECT_SUCCEEDED'),

    address_failed: createAction('LOOKUP_ADDRESS_FAILED'),

//vehicle lookup
    make: createAction('LOOKUP_MAKE'),
    model: createAction('LOOKUP_MODEL'),
    year: createAction('LOOKUP_YEAR'),
    body_type: createAction('LOOKUP_BODY_TYPE'),
    transmission: createAction('LOOKUP_TRANSMISSION'),
    fuel_type: createAction('LOOKUP_FUEL_TYPE'),
    spec: createAction('LOOKUP_SPEC'),

    cdl: createAction('LOOKUP_CDL'),

    vehicle_succeeded: createAction('LOOKUP_VEHICLE_SUCCEEDED'),
    vehicle_failed: createAction('LOOKUP_VEHICLE_FAILED'),

    vehicle_reset: createAction('LOOKUP_RESET_VEHICLE')
};

export default actions;

export const resetAddressLookup = () => actions.reset_address();
export const search = data => apiPost('lookup/address/search', data, actions.search, actions.search_succeeded, actions.address_failed);
export const select = data => apiPost('lookup/address/select', data, actions.select, actions.select_succeeded, actions.address_failed);

export const cdl = data => apiPost('lookup/vehicle/cdl', data, actions.cdl, actions.vehicle_succeeded, actions.vehicle_failed);
export const make = () => apiGet('lookup/vehicle/make', null, actions.make, actions.vehicle_succeeded, actions.vehicle_failed);
export const model = data => apiPost('lookup/vehicle/model', data, actions.model, actions.vehicle_succeeded, actions.vehicle_failed);
export const year = data => apiPost('lookup/vehicle/year', data, actions.year, actions.vehicle_succeeded, actions.vehicle_failed);
export const bodyType = data => apiPost('lookup/vehicle/body-type', data, actions.body_type, actions.vehicle_succeeded, actions.vehicle_failed);
export const transmission = data => apiPost('lookup/vehicle/transmission', data, actions.transmission, actions.vehicle_succeeded, actions.vehicle_failed);
export const fuelType = data => apiPost('lookup/vehicle/fuel', data, actions.fuel_type, actions.vehicle_succeeded, actions.vehicle_failed);
export const spec = data => apiPost('lookup/vehicle/spec', data, actions.spec, actions.vehicle_succeeded, actions.vehicle_failed);

export const resetVehicleLookup = () => actions.vehicle_reset();


