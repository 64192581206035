import {initialValues} from './utils/van/initial.values';

import {
    BLANK_DRIVER_TEST,
    BLANK_VEHICLE_TEST
} from './utils/van/types';


const STATE_QUOTED = 'quoted';
const STATE_BUY_PAGE_SUBMITTED = 'buy_page_submitted';
const STATE_OFFLINE_ONLY = 'offline_only';
const STATE_LICENCE_UNDER_12 = 'lead_licence_under_12';
const STATE_DRIVER_UNDER_21 = 'lead_driver_under_21';
const STATE_OGI_QUEUED_FOR_DELIVERY = 'ogi_queued_for_delivery';
const STATE_OGI_GOT_QUOTE = 'ogi_got_quote';
const STATE_DATA_SUBMITTED = 'data_submitted';
const STATE_OGI_SENT_TO_BROKER = 'ogi_sent_to_broker';
const STATE_GRANITE_SENT = 'granite_sent';
const STATE_OPTIONS_PENDING = 'options_pending';
const STATE_INVITE_PENDING = 'invite_pending';//Ready to send email and sms
const STATE_OGI_ID_SCORE_VERIFIED = 'ogi_id_score_verified';
const STATE_FIRST_UNDERWRITING_SENT = 'first_underwriting_sent';
const STATE_KINETIQ_SENT = 'kinetiq_sent';
const STATE_INSHUR_SENT = 'inshur_sent';
const STATE_TRADEX_SENT = 'tradex_sent';
const STATE_KGM_SENT = 'kgm_sent';
const STATE_ERS_SENT = 'ers_sent';
const STATE_LVE_SENT = 'lve_sent';
const STATE_DECLINED = 'declined';
const STATE_QUOTE_EXPIRED = 'quote_expired';
const STATE_ID_SCORE_FAILED = 'id_score_failed';

const STATE_PAYMENT_PENDING = 'payment_pending';
const STATE_PAYMENT_CANCELLED = 'payment_cancelled';
const STATE_PAYMENT_EXPIRED = 'payment_expired';
const STATE_PAID = 'paid';

const STATE_SELECTED = 'selected';

const STATE_LEAD_READY_FOR_QUOTE = 'lead_ready_for_quote';
const STATE_NEW_LEAD = 'new_lead';
const STATE_LEAD_IN_PROGRESS = 'lead_in_progress';

const STATE_PAYMENT_PROCESSED = 'payment_processed';

//van additional states
const STATE_OGI_QUOTED_FOR_SCORE = 'ogi_quoted_for_score';
const STATE_SENT_FOR_QUOTES = 'sent_for_quotes'
const STATE_OGI_SENT_TO_BROKER_FOR_SCORE = 'ogi_sent_to_broker_for_score'

//ogi get quotes state
const STATE_OGI_TPFT_SENT = 'ogi_tpft_sent';
const STATE_OGI_TP_SENT = 'ogi_tp_sent';
const STATE_OGI_COMPREHENSIVE_SENT = 'ogi_comprehensive_sent';

const states = {
    STATE_OGI_COMPREHENSIVE_SENT,
    STATE_OGI_TP_SENT,
    STATE_OGI_TPFT_SENT,
    STATE_QUOTED,
    STATE_BUY_PAGE_SUBMITTED,
    STATE_OFFLINE_ONLY,
    STATE_LICENCE_UNDER_12,
    STATE_DRIVER_UNDER_21,
    STATE_OGI_GOT_QUOTE,
    STATE_OGI_QUEUED_FOR_DELIVERY,
    STATE_DATA_SUBMITTED,
    STATE_OGI_SENT_TO_BROKER,
    STATE_OGI_ID_SCORE_VERIFIED,
    STATE_FIRST_UNDERWRITING_SENT,
    STATE_KINETIQ_SENT,
    STATE_INSHUR_SENT,
    STATE_GRANITE_SENT,
    STATE_TRADEX_SENT,
    STATE_KGM_SENT,
    STATE_LVE_SENT,
    STATE_ERS_SENT,
    STATE_OPTIONS_PENDING,
    STATE_INVITE_PENDING,
    STATE_DECLINED,
    STATE_QUOTE_EXPIRED,
    STATE_ID_SCORE_FAILED,

    STATE_PAYMENT_PENDING,
    STATE_PAYMENT_CANCELLED,
    STATE_PAYMENT_EXPIRED,
    STATE_PAID,

    STATE_SELECTED,

    STATE_LEAD_READY_FOR_QUOTE,
    STATE_NEW_LEAD,
    STATE_LEAD_IN_PROGRESS,

    STATE_PAYMENT_PROCESSED,
    //van additional states
    STATE_OGI_QUOTED_FOR_SCORE,
    STATE_SENT_FOR_QUOTES,
    STATE_OGI_SENT_TO_BROKER_FOR_SCORE

};

const PROVIDER_OPENGI = 'opengi';
const PROVIDER_GRANITE = 'granite';
const PROVIDER_TRADEX = 'tradex';
const PROVIDER_KINETIQ = 'kinetiq';
const PROVIDER_FIRST_UNDERWRITING = 'first_underwriting';
const PROVIDER_INSHUR = 'inshur';

const providers = {
    PROVIDER_FIRST_UNDERWRITING,
    PROVIDER_GRANITE,
    PROVIDER_OPENGI,
    PROVIDER_TRADEX,
    PROVIDER_KINETIQ,
    PROVIDER_INSHUR
};

const timeTable = {
    week: {
        start: '9:00am',
        end: '7:00pm',
        text: 'Mon-Fri 9am-7pm',
    },
    saturday: {
        start: '10:00am',
        end: '2:00pm',
        text: 'Sat 10am-2pm',
    },
};

const phoneNumbers = {
    office: '0330 808 1500',
    undefined: '0330 808 1500',
    '': '0330 808 1500',
    fastfood: '0330 808 9056',
    van: '0330 808 1457',
    "courier-van": '0330 808 1457',
};

const isProduction = () => process.env.NODE_ENV === 'production';
export {isProduction};

console.log(process.env.NODE_ENV);

const config = {
    initialValues,
    backend_url: isProduction() ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL,
    driver: BLANK_DRIVER_TEST,
    vehicle: BLANK_VEHICLE_TEST,
    phoneNumbers,
    timeTable,
    states,
    providers
};

export default config;
