import { handleActions } from "redux-actions";
import actions from "../actions/admin.leads";
import { API_END, API_START } from "../constants/api.constants";

import _ from "lodash";

export default handleActions(
  {
    [actions.get_leads]: (state, action) => {
      switch (action.payload) {
        case API_START:
          return {
            ...state,
            isLoading: true,
            errors: {},
          };
        case API_END:
          return { ...state, isLoading: false };
        default:
          return state;
      }
    },
    [actions.get_leads_list_succeeded]: (state, action) => {
      const failReason = _.get(action, "payload.fail_reason", "");
      const totalItems = _.get(action, "payload.count", 0);

      return {
        ...state,
        leads: action.payload,
        totalItems,
        failReason,
      };
    },
    [actions.leads_failed]: (state, action) => {
      const errors = _.get(action, "payload.data.errors");
      return {
        ...state,
        errors: !_.isEmpty(errors)
          ? errors
          : _.get(action, "payload.data.message") || "Please try later",
      };
    },
  },
  getDefaults()
);

export function getDefaults() {
  return {
    isLoading: false,
    errors: {},
  };
}
