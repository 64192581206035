import _ from "lodash";


const SECTION_STEP_FORM = 'step-form';
const SECTION_GRANITE_SENDER = 'granite-sender'
const SECTION_QUOTES = 'quotes'
const SECTION_PAYMENTS = 'payments'

export const SECTIONS = {
    SECTION_STEP_FORM,
    SECTION_GRANITE_SENDER,
    SECTION_QUOTES,
    SECTION_PAYMENTS
};

export const ROLES = {
    ADMIN: 'admin',
    USER: 'user',
};

/*
 user: {
   email,
   isSuperAdmin,
   roles: {
      isOverallAdmin,
      isOverallUser,
      sections: {
         name: {name, role}
      }
   }
 }
 */

const isSuperAdmin = (user) => _.get(user, 'isSuperAdmin', false);

const isOverallAdmin = (user) => _.get(user, 'roles.isOverallAdmin', false);

const isOverallUser = (user) => _.get(user, 'roles.isOverallUser', false);


export const getUserEmail = adminProps => _.get(adminProps, 'login.email', '');

export const parseEmail = (email = '') => email.split('@')[0].split('.').join(' ');

export const canAccess = (user, role, section) => {
    return isSuperAdmin(user) ||
        isOverallAdmin(user) ||
        (role === 'user' && isOverallUser(user)) ||
        _.get(user, `roles.sections.${section}.role`) === role;
}

export const isAuthorized = user => {
    const access_token = _.get(user, 'access_token', null);
    //todo check token expired
    return !!(user && access_token && access_token.length);
};
