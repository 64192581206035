import {handleActions} from "redux-actions";
import actions from "../actions/unsubscribe";
import {API_END, API_START} from "../constants/api.constants";
import _ from "lodash";

export function getDefaults() {
    return {
        unsubscribe: {
            isLoading: false,
            errors: {},
            message: null,
        }
    };
}

export default handleActions(
    {
        [actions.unsubscribe_processing]: (state, action) => {
            switch (action.payload) {
                case API_START:
                    return {
                        ...state,
                        unsubscribe: {
                            ...getDefaults().unsubscribe,
                            isLoading: true,
                        },
                    };
                case API_END:
                    return {
                        ...state,
                        unsubscribe: {
                            ...state.unsubscribe,
                            isLoading: false,
                        },
                    };
                default:
                    return state;
            }
        },
        [actions.unsubscribe_succeeded]: (state, action) => {
            return {
                ...state,
                unsubscribe: {
                    ...state.unsubscribe,
                    message: "unsubscribed successfully",
                },
            };
        },
        [actions.unsubscribe_failed]: (state, action) => {
            const errors = _.get(action, "payload.data.errors");

        return {
            ...state,
            unsubscribe: {
                ...state.unsubscribe,
                errors: !_.isEmpty(errors)
                    ? errors
                    : _.get(action, "payload.data.message"),
            },
        };
    },
    },
    getDefaults()
);
