import { createAction } from "redux-actions";
import { apiGet } from "./api";

const actions = {
  get_leads_list_succeeded: createAction("GET_LEADS_LIST_SUCCEEDED"),

  get_leads: createAction("GET_LEADS"),
  admin_leads_succeeded: createAction("ADMIN_LEADS_SUCCEEDED"),
  leads_failed: createAction("LEADS_FAILED"),
};

export default actions;

export const getLeadsList = (data) =>
  apiGet(
    "admin/leads",
    data,
    actions.get_leads,
    actions.get_leads_list_succeeded,
    actions.leads_failed
  );

export const getQuotes = (id) =>
  apiGet(
    "admin/quotes/quote",
    id,
    actions.get_quotes,
    actions.quote_succeeded,
    actions.quote_failed
  );
