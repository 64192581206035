import {submit, get, reset} from "../redux/actions/id.score";
import {connect} from "react-redux";
import IdScoreForm from "../components/forms/id.score.form";

const mapStateToProps = (state, onwProps) => {
    const {uuid} = onwProps.match.params;
    return {
        lead_uuid: uuid,
        ...state?.idScore,
        ...onwProps,
    };
};

const mapDispatchToProps = (dispatch) => {
    return ({
        submit: (data) => dispatch(submit(data)),
        get: (data) => dispatch(get(data)),
        reset: () => dispatch(reset())
    });
}

export default connect(mapStateToProps, mapDispatchToProps)(IdScoreForm);
