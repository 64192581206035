import {handleActions} from "redux-actions";
import actions from "../actions/additional.quote.details";
import {API_END, API_START} from "../constants/api.constants";

import _ from "lodash";

export default handleActions(
    {
        [actions.get]: (state, action) => {
            switch (action.payload) {
                case API_START:
                    return {
                        ...state,
                        isLoading: true,
                        errors: {},
                        data: {
                            initialData: null,
                            risk: null,
                            originalQuote: null,
                            newQuote: null
                        },
                    };
                case API_END:
                    return {...state, isLoading: false};
                default:
                    return state;
            }
        },
        [actions.submit]: (state, action) => {
            switch (action.payload) {
                case API_START:
                    return {
                        ...state,
                        data: {
                            ...state.data, originalQuote: null,
                            newQuote: null
                        },
                        errors: {},
                        isSubmitting: true
                    };
                case API_END:
                    return {...state, isSubmitting: false};
                default:
                    return state;
            }
        },
        [actions.get_succeeded]: (state, action) => {
            return {
                ...state,
                data: action.payload,
                errors: {}
            };
        },

        [actions.submit_succeeded]: (state, action) => {
            const newQuote = _.get(action, 'payload.newQuote', null)
            const originalQuote = _.get(action, 'payload.originalQuote', null)

            return {
                ...state,
                data: {...state.data, newQuote, originalQuote},
                errors: {}
            };
        },
        [actions.get_failed]: (state, action) => {
            const errors = _.get(action, "payload.data.errors");
            return {
                ...state,
                errors: !_.isEmpty(errors)
                    ? errors
                    : _.get(action, "payload.data.message") || 'Error getting your details, please try later or call us.',
            };
        },
        [actions.submit_failed]: (state, action) => {
            const errors = _.get(action, "payload.data.errors");
            return {
                ...state,
                errors: !_.isEmpty(errors)
                    ? errors
                    : _.get(action, "payload.data.message") || 'Error submitting your details, please try later or call us.',
            };
        },

    },
    getDefaults()
);

export function getDefaults() {
    return {
        isLoading: false,
        data: {
            risk: null,
            initialData: null,
            originalQuote: null,
            newQuote: null
        },
        errors: {},
        isSubmitting: false,
    };
}
