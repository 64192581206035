import React from 'react';
import {Label, Input, FormGroup} from 'reactstrap';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

const RadioButton = ({label, name, value, checked, onChange, innerRef, error, styles}) => {
    return (
        <FormGroup className={"radio-button-wrapper"}>
            <Input
                {...{
                    type: "radio",
                    name,
                    value,
                    id: label,
                    checked,
                    innerRef,
                    onChange: () => onChange(value)
                }}
            />
            <Label className={`${error ? 'is-invalid' : ''}`} style={styles} for={label}>
                <span className={`${checked ? 'selected' : ''} icon`}><FontAwesomeIcon icon={faCheck}/></span>
                {label.toLowerCase()}
            </Label>

        </FormGroup>
    )
};
RadioButton.defaultProps = {
    styles: {}
}
RadioButton.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    error: PropTypes.any,
    innerRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({current: PropTypes.any})
    ]),
    styles: PropTypes.object
};

export default RadioButton;