import {createAction} from "redux-actions";
import {apiGet, apiPost} from "./api";

const actions = {
    get: createAction("ADDITIONAL_QUOTE_DETAILS_GET"),
    submit: createAction("ADDITIONAL_QUOTE_DETAILS_SUBMIT"),
    get_succeeded: createAction("ADDITIONAL_QUOTE_DETAILS_GET_QUOTE_SUCCEEDED"),
    get_failed: createAction("ADDITIONAL_QUOTE_DETAILS_FAILED"),
    submit_succeeded: createAction("ADDITIONAL_QUOTE_DETAILS_SUBMIT_SUCCEEDED"),
    submit_failed: createAction("ADDITIONAL_QUOTE_DETAILS_SUBMIT_FAILED"),
};

export default actions;

export const get = (additionalDetailKey = '', childQuoteUUID= '') =>
    apiGet(
        `quote/additional-details/${additionalDetailKey}/${childQuoteUUID}`,
        {},
        actions.get,
        actions.get_succeeded,
        actions.get_failed
    );


export const submit = (additionalDetailKey = '', childQuoteUUID = '', data={}) =>
    apiPost(
        `quote/additional-details/${additionalDetailKey}/${childQuoteUUID}`,
        data,
        actions.submit,
        actions.submit_succeeded,
        actions.submit_failed
    )